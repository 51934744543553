<template>
    <div class="app-container" @click="visible = false;">
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="">
                    <el-col :span="6" class="">
                        <el-form-item label="所属客户">
                            <el-select @change="changeCustom" v-model="queryParams.customId" clearable placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="所属组织">
                            <el-tree-select ref="tsd" default-expand-all node-key="orgId" clearable @change="changeOrg"
                                v-model="queryParams.orgId" check-strictly :data="orgList" placeholder="请选择组织" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="所属群组">
                            <el-select @change="changeQun" v-model="queryParams.qunId" clearable placeholder="请选择客户">
                                <el-option v-for="item in qunList" :key="item.id" :label="(item.qunName)"
                                    :value="item.id" />
                            </el-select>

                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="本子名称">
                            <el-select v-model="queryParams.qunRecordId" clearable placeholder="请选择本子">
                                <el-option v-for="item in qunBookList" :key="item.resourceId" :label="(item.recordName)"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" class="">
                        <el-form-item label="书写时间">
                            <el-date-picker style="width: 100%;" value-format="x" v-model="queryParams.writeTime"
                                type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="排序方式" prop="sortType">
                            <el-select style="width: 100%;" v-model="queryParams.sortType" placeholder="请选择排序方式">
                                <el-option :label="'最后书写时间倒序'" :value="0" />
                                <el-option :label="'书写页数倒排'" :value="2" />
                                <el-option :label="'群组成员顺序'" :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mr0">
                        <el-form-item label="姓名">
                            <el-input style="width: 100%;" v-model="queryParams.keywords" placeholder="请输入姓名"
                                class="input-with-select">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" class="mb0">
                        <el-button style="width:100px" class="pull-right" type="primary" @click="getList()">查找</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div style="display: flex;justify-content: space-between;position: relative;" class="mb10">
                <img @click="isSxShow = true;" v-if="!isSxShow"
                    style="    transform: translate(-50%, -36px);left: 50%;top: 50%;" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt="">
                <el-radio-group v-model="fmType">
                    <el-radio-button label="封面展示" value="封面展示" />
                    <el-radio-button label="笔记页展示" value="笔记页展示" />
                </el-radio-group>
                <div>
                    <el-button style="width:100px" class="" :type="queryParams.qunRecordId ? 'primary' : 'info'"
                        @click="goBjgl()">笔迹概览</el-button>
                    <div class="pull-right ml15">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="handleQuery">
                                <el-row class="mt15">
                                    <el-col :span="24" class="mr0" v-if="!role.includes(1)">
                                        <el-form-item label="所属客户" class="w-100">
                                            <el-select class="w-100" @change="changeCustom" v-model="queryParams.customId"
                                                clearable placeholder="请选择客户">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="所属组织" class="w-100">
                                            <el-tree-select class="w-100" ref="tsd" default-expand-all node-key="orgId"
                                                clearable @change="changeOrg" v-model="queryParams.orgId" check-strictly
                                                :data="orgList" placeholder="请选择组织" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="所属群组" class="w-100">
                                            <el-select class="w-100" @change="changeQun" v-model="queryParams.qunId"
                                                clearable placeholder="请选择客户">
                                                <el-option v-for="item in qunList" :key="item.id" :label="(item.qunName)"
                                                    :value="item.id" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="本子名称" class="w-100">
                                            <el-select class="w-100" v-model="queryParams.qunRecordId" clearable
                                                placeholder="请选择本子">
                                                <el-option v-for="item in qunBookList" :key="item.resourceId"
                                                    :label="(item.isStoraged == 1?(item.recordName+'(已归档)'):item.recordName)" :value="item.id" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="书写时间" class="w-100">
                                            <el-date-picker class="w-100" value-format="x" v-model="queryParams.writeTime"
                                                type="daterange" range-separator="至" start-placeholder="开始日期"
                                                end-placeholder="结束日期" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="排序方式" prop="sortType" class="w-100">
                                            <el-select class="w-100" v-model="queryParams.sortType" placeholder="请选择排序方式">
                                                <el-option :label="'最后书写时间倒序'" :value="0" />
                                                <el-option :label="'书写页数倒排'" :value="2" />
                                                <el-option :label="'群组成员顺序'" :value="1" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="姓名" class="w-100">
                                            <el-input class="w-100" v-model="queryParams.keywords" placeholder="请输入姓名">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </div>
            </div>
            <el-row :style="'height: calc(100vh - ' + (isSxShow ? '212' : '205') + 'px);overflow-y: auto;'">
                <div style="display: flex;flex-wrap:wrap;width: 100%;" class="sdfsa">
                    <el-card class="cursor" style="margin-bottom: 10px;" v-for="(item, index) in dataXinList" :key="index"
                        @click="goNewDetail(item)" :body-style="{ padding: '0px' }">
                        <div style="padding:14px 14px 0 14px;margin:0 auto;width: 130px;position: relative;">
                            <img v-if="fmType == '封面展示' && item.resourceType == 0" style="width: 130px;height: auto;"
                                :src="this.$store.state.zdy.imgUrl + '/book/' + item.resourceId + '/img/cover.png_original'"
                                alt="" class="image">
                            <img v-if="fmType == '封面展示' && item.resourceType == 1" style="width: 130px;height: auto;"
                                :src="item.book.formUrl + '/1.png'" alt="" class="image">
                            <!-- <div v-if="fmType == '笔记页展示' && item.lastWritePage"
                                style="position: absolute;right: 0;top: 0;z-index: 999;background: rgb(56, 148, 255);color: #fff;font-size: 10px;padding: 2px 5px;border-radius: 15px;">
                                {{ getPageName(item.lastWritePage,item.book.pageCount,item.book.headPage,item.book.tailPage) }}</div> -->
                            <div v-if="item.lastWritePage"
                                style="position: absolute;right: 0;top: 0;z-index: 999;background: rgb(56, 148, 255);color: #fff;font-size: 14px;padding: 2px 5px;border-radius: 15px;">
                                {{ item.memberName }}
                            </div>
                            <Canvasbox v-if="fmType == '笔记页展示' && item.book && item.resourceType == 0" :bookData="item.book"
                                :imgList="item.pageData ? item.pageData.illustrationList : []" :imwidth="130"
                                :imheight="item.book.pageHeight / item.book.pageWidth * 130"
                                :canvasid="item.id + '22323233' + index"
                                :picSrc="this.$store.state.zdy.imgUrl + '/book/' + item.resourceId + '/img/' + (item.lastWritePage ? item.lastWritePage : 1) + '.png_original'"
                                :detail="item.pageData ? item.pageData.strokeList : []">
                            </Canvasbox>
                            <Canvasbox v-if="fmType == '笔记页展示' && item.book && item.resourceType == 1" :bookData="item.book"
                                :imgList="item.pageData ? item.pageData.illustrationList : []" :imwidth="130"
                                :imheight="item.book.pageHeight / item.book.pageWidth * 130"
                                :canvasid="item.id + '22323233' + index"
                                :picSrc="item.book.formUrl + '/' + (item.lastWritePage ? item.lastWritePage : 1) + '.png'"
                                :detail="item.pageData ? item.pageData.strokeList : []">
                            </Canvasbox>

                        </div>
                        <div style="padding: 5px 14px 5px 14px">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div class="text-one fs-12" style="">{{
                                    timestampToTime(item.lastWriteTime) ?
                                    timestampToTime(item.lastWriteTime) : '暂无书写时间'
                                }}</div>
                                <div class="fs-12">{{ item.writeCount }}页</div>
                            </div>
                            <!-- <div class="text-one bottom  fs-14 mb5" :title="item.recordName"
                                style="height: 15px;font-size: 12px;">{{ item.recordName }}</div> -->
                            <!-- <div class="bottom  fs-12" style="">
                                <div style="height: 15px;font-size: 12px;">{{
                                    timestampToTime(item.lastWriteTime) ?
                                    timestampToTime(item.lastWriteTime) : '暂无书写时间'
                                }}</div>
                            </div> -->
                        </div>
                    </el-card>
                    <el-empty style="width:100%;" v-if="dataXinList.length == 0" description="暂无数据"></el-empty>
                </div>
                <!-- <el-col :span="24" v-if="isShow">
                    <el-collapse class="" @change="changeCollapse">
                        <el-collapse-item :name="indexs" v-for="(items, indexs) in dataList" v-show="items.length != 0">
                            <template #title>
                                <div style="display: flex;align-items:center;color: #e27F11;">
                                    <el-icon :size="18">
                                        <Notebook />
                                    </el-icon>
                                    <div class="ml5" style="font-weight: bold;">
                                        {{ returnBookName(items[0]) }}
                                    </div>
                                </div>
                            </template>
                            <div style="display: flex;flex-wrap:wrap;" class="sdfsa">
                                <el-card class="cursor" style="margin-bottom: 10px;" v-for="(item, index) in items"
                                    :key="index" @click="goDetail(item, items)" :body-style="{ padding: '0px' }">
                                    <div style="padding:14px 14px 0 14px;margin:0 auto;">
                                        <img v-if="fmType == '封面展示'" style="width: 130px;height: auto;"
                                            :src="this.$store.state.zdy.imgUrl+'/book/' + item.resourceId + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <Canvasbox v-if="item.iscanvas && fmType == '笔记页展示'" :bookData="item"
                                            :imgList="item.illustrationList" :imwidth="130"
                                            :imheight="item.pageHeight / item.pageWidth * 130"
                                            :canvasid="item.id + '22323233' + index"
                                            :picSrc="this.$store.state.zdy.imgUrl+'/book/' + item.bookId + '/img/' + (item.lastWritePage ? item.lastWritePage : 1) + '.png_original'"
                                            :detail="item.strokeList">
                                        </Canvasbox>
                                    </div>
                                    <div style="padding: 5px 14px 14px 14px">
                                        <div class="text-one" :title="item.userName" style="font-weight: bold;">{{
                                            item.userName }}</div>
                                        <div class="text-one bottom  fs-14 mb5" :title="item.recordName"
                                            style="height: 15px;font-size: 12px;">{{ item.recordName }}</div>
                                        <div class="bottom  fs-12" style="">
                                            <div style="height: 15px;font-size: 12px;">{{
                                                timestampToTime(item.lastWriteTime) ?
                                                timestampToTime(item.lastWriteTime) : '暂无书写时间'
                                            }}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                        </el-collapse-item>

                    </el-collapse>
                    <el-empty style="margin: 0 auto;padding-top: 10vh;" v-if="noteBook.length == 0" :image-size="200" />
                </el-col> -->
            </el-row>
            <!-- <el-row v-for="(items, indexs) in dataList">
                <el-col class="w-90" v-for="(item, index) in items" :key="index" :xs="8" :sm="6" :md="4">
                    <el-card @click="goDetail(item, 1)" :body-style="{ padding: '0px' }">
                        <div style="padding:14px 14px 0 14px;margin:0 auto;">
                            <img :src="this.$store.state.zdy.imgUrl+'/book/' + item.resourceId + '/img/cover.png_original'"
                                alt="" class="image">
                        </div>
                        <div style="padding: 5px 14px 14px 14px">
                            <div class="text-one" style="font-weight: bold;">{{ item.userName }}</div>

                            <div class="bottom  fs-14">
                                <div>{{ item.lastWriteTime }}</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-empty style="margin: 0 auto;padding-top: 10vh;" v-if="dataList.length == 0" :image-size="200" />
            </el-row> -->
        </el-card>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { noteotherBooks, notepageData, noteConditionBooks, noteBookQunBookList } from "@/api/adminservice/book";
import { queryTreeCustomOrg, queryCustoms } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { bookList } from "@/api/adminservice/public";

export default {
    name: "CloudBook",
    components: {
    },
    data() {
        return {
            visible: false,
            isSxShow: true,
            timestampToTime,
            active: 1,
            isAuth: this.$storage.get('isAuth'),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            issuedBookAuth: this.$storage.get('issuedBookAuth'),
            orgList: [],
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            teacherBookId: '',
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            dataXinList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            isDetail: false,
            peoList: [],
            qzList: [],
            // 查询参数
            queryParams: {
                title: '',
                qzId: '',
                teacherId: '',
                customId: '',
                qunId: '',
                orgId: '',
                keywords: '',
                writeTime: [],
                sortType: 1,
                qunRecordId: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                taskTitle: [
                    { required: true, message: "请输入任务标题", trigger: "blur" }
                ],
                taskContent: [
                    { required: true, message: "任务内容", trigger: "blur" }
                ]
            },
            activeName: '',
            customList: [],
            qunList: [],
            bookList: [],
            qunIdList: [],
            isShow: false,
            fmType: '封面展示',
            noteBook: [],
            qunBookList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        if (this.$storage.get("role").includes(1)) {
            this.queryParams.customId = this.$storage.get("customId")
            this.changeCustom()
        }
        this.getCustoms();
    },
    mounted() {
        bookList().then(res => {
            this.bookList = res.data.data;
            // this.getList();
        })
    },
    methods: {
        goBjgl() {
            if (this.queryParams.qunRecordId) {
                this.qunBookList.forEach(e => {
                    if (e.id == this.queryParams.qunRecordId) {
                        let routeUrl = this.$router.resolve({
                            path: '/overviewHand',// 这里的路径就可以正常的写，不需要添加_blank: true
                            query: { userRecordId: '', bookData: JSON.stringify(e.book), qunRecordId: this.queryParams.qunRecordId, qunId: this.queryParams.qunId }
                        })
                        window.open(routeUrl.href, '_blank')
                    }
                })
            }
        },
        changeQun() {
            this.qunBookList = [];
            this.queryParams.qunRecordId = '';
            if (this.queryParams.qunId) {
                noteBookQunBookList({ qunId: this.queryParams.qunId }).then(res => {
                    this.qunBookList = res.data.data;
                })
            }
            // this.getList();
        },
        changeCollapse(activeNames) {
            // console.log(activeNames, this.dataList[activeNames]);
        },
        returnBookName(item) {
            // console.log(item);
            if (item) {
                for (var i = 0; i < this.bookList.length; i++) {
                    if (this.bookList[i].id == item.resourceId) {
                        // console.log(this.bookList[i].bookName);
                        return this.bookList[i].bookName
                    }
                }
            }
        },
        getPageName(currentpageNum, totalPages, headPages, tailPages) {
            var pageNum = null;
            var tailpageNum = totalPages - currentpageNum;

            if (tailPages > tailpageNum) {
                // 尾页
                if (tailPages - tailpageNum == 0) {
                    // 尾页最后一页
                    pageNum = "尾页-" + tailPages;
                } else {
                    pageNum = "尾页-" + (tailPages - tailpageNum);
                }
            } else if (headPages >= currentpageNum) {
                // 扉页
                pageNum = "扉页-" + currentpageNum
            } else {
                // 正常页码
                pageNum = "第" + (currentpageNum - headPages) + "页"
            }
            return pageNum;
        },
        getTreeCustom() {
            queryTreeCustomOrg({ customId: this.$storage.get('customId') ? this.$storage.get('customId') : this.queryParams.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                this.orgList = res.data.data;
            })
        },
        changeCustom() {
            this.qunIdList = [];
            this.qunBookList = [];
            this.orgList = [];
            this.queryParams.qunRecordId = '';
            this.queryParams.orgId = '';
            this.queryParams.qunId = '';
            this.qunList = [];
            queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                res.data.data.forEach(e => {
                    this.qunIdList.push(e.id)
                })
                this.getTreeCustom()
                // this.getList()
            })
        },
        changeOrg() {
            this.qunBookList = [];
            this.queryParams.qunRecordId = '';
            this.qunIdList = [];
            this.queryParams.qunId = ''
            queryCustomQun({ customId: this.$storage.get('customId') ? this.$storage.get('customId') : this.queryParams.customId }).then(res => {
                if (this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgId == this.queryParams.orgId
                    })
                }
                this.qunList = res.data.data;
                // console.log(this.qunList);
            })
            // this.getList()
        },
        getCustoms() {
            this.customList = [];
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('030');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息技术有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
                // console.log(this.customList);
            });
        },
        goNewDetail(row) {
            // console.log(row)
            let routeUrl = this.$router.resolve({
                path: '/readBook',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userRecordId: row.id, bookData: JSON.stringify(row.book), qunRecordId: row.qunRecordId, qunId: row.qunId, queryParams: JSON.stringify(this.queryParams) }
            })
            window.open(routeUrl.href, '_blank')
        },
        goDetail(row, rowList) {
            // console.log(row, rowList)
            var bookData = {}
            var peopleIdList = []
            rowList.forEach(e => {
                peopleIdList.push(e.id)
            })
            this.bookList.forEach(e => {
                if (row.resourceId == e.id) {
                    bookData = e;
                }
            })
            let routeUrl = this.$router.resolve({
                path: '/readBook',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userRecordId: row.id, bookData: JSON.stringify(bookData), peopleIdList: JSON.stringify(peopleIdList) }
            })
            window.open(routeUrl.href, '_blank')
            // if (!num) {
            //     let routeUrl = this.$router.resolve({
            //         path: '/readBook',// 这里的路径就可以正常的写，不需要添加_blank: true
            //         query: { teacherBookId: this.teacherBookId }
            //     })
            //     window.open(routeUrl.href, '_blank')
            // } else {
            //     let routeUrl = this.$router.resolve({
            //         path: '/readBook',// 这里的路径就可以正常的写，不需要添加_blank: true
            //         query: { teacherBookId: this.teacherBookId, isMineBook: 1 }
            //     })
            //     window.open(routeUrl.href, '_blank')
            // }
        },
        handleClick(pane, e) {
            this.queryParams.title = ''
            this.queryParams.qzId = ''
            this.queryParams.teacherId = ''
            this.getList();
        },
        tgMethod(e) {
            this.teacherBookId = ''
            this.queryParams.title = ''
            this.open = false;
            this.isDetail = false;
        },
        /** 查询基础：基数数据列表 */
        getList() {
            if (!this.queryParams.customId) {
                this.$message.warning("请选择客户")
                return;
            }
            if (!this.queryParams.orgId) {
                this.$message.warning("请选择组织")
                return;
            }
            if (!this.queryParams.qunId) {
                this.$message.warning("请选择群组")
                return;
            }
            this.dataXinList = [];
            if (this.queryParams.writeTime && this.queryParams.writeTime.length != 0) {
                this.queryParams.beginTime = this.queryParams.writeTime[0]
                this.queryParams.endTime = this.queryParams.writeTime[1]
            } else {
                this.queryParams.beginTime = ''
                this.queryParams.endTime = ''
            }

            this.loading = false;
            this.isShow = false;
            this.dataList = []
            this.noteBook = []
            var obj = {}
            noteConditionBooks(this.queryParams).then(res => {
                // console.log(res);
                this.dataXinList = res.data.data;
            })
            // noteotherBooks({}).then(res => {
            //     res.data.data.forEach(e => {
            //         e.qunList.forEach(j => {
            //             j.qunBookList.forEach(m => {
            //                 m.bookList.forEach(x => {
            //                     x.orgId = e.orgId;
            //                     x.orgName = e.orgName;
            //                     x.qunId = j.qunId;
            //                     x.qunName = j.qunName;
            //                     this.noteBook.push(x);
            //                     var txt = x.resourceId;
            //                     obj[x.resourceId] = []
            //                 })
            //             })
            //         })
            //     })
            //     if (this.queryParams.customId != '') {
            //         this.noteBook = this.noteBook.filter(itemA => {
            //             return this.qunIdList.indexOf(itemA.qunId) != -1;
            //         })
            //     }
            //     if (this.queryParams.orgId != '') {
            //         this.noteBook = this.noteBook.filter(itemA => {
            //             return itemA.orgId == this.queryParams.orgId
            //         })
            //     }
            //     if (this.queryParams.qunId != '') {
            //         this.noteBook = this.noteBook.filter(itemA => {
            //             return itemA.qunId == this.queryParams.qunId
            //         })
            //     }
            //     if (this.queryParams.userName != '') {
            //         this.noteBook = this.noteBook.filter(itemA => {
            //             return itemA.userName.indexOf(this.queryParams.userName) != -1
            //         })
            //     }
            //     this.noteBook.forEach(e => {
            //         obj[e.resourceId].push(e)
            //     })
            //     console.log(this.noteBook)
            //     this.dataList = obj;
            //     this.isShow = true;
            //     for (let key in this.dataList) {
            //         this.dataList[key].forEach(e => {
            //             e.illustrationListd = [];
            //             e.strokeListd = [];
            //             e.iscanvas = true;
            //             for (var i = 0; i < this.bookList.length; i++) {
            //                 if (key == this.bookList[i].id) {
            //                     e.bookId = this.bookList[i].id;
            //                     e.pageHeight = this.bookList[i].pageHeight
            //                     e.pageWidth = this.bookList[i].pageWidth
            //                     break;
            //                 }
            //             }
            //             if (e.lastWritePage) {
            //                 e.iscanvas = false;
            //                 notepageData({ userRecordId: e.id, pageNum: e.lastWritePage }).then(res => {
            //                     e.illustrationList = res.data.data.illustrationList;
            //                     e.strokeList = res.data.data.strokeList;
            //                     e.iscanvas = true;
            //                     console.log(e);
            //                 })
            //             }
            //         })
            //     }
            // })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.open = true;
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId

                    // console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        // console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        e.children = e.children.filter(itemA => {
                            return itemA.orgType != 5;
                        })
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.taskId = row.taskId;
            this.open = true;
        }
    }
};
</script>
<style scoped>
.sdfsa .el-card {
    /* 让最后一个元素的右边距自动适应，从而实现左对齐的效果。 */
    margin-left: 10px;
    margin-right: 10px;
}

.sdfsa .el-card:last-child {
    /* 让最后一个元素的右边距自动适应，从而实现左对齐的效果。 */
    margin-right: auto;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-tabs--border-card {
    border: 0;
}

/deep/ .el-tabs__header {
    background-color: transparent !important;
}

/deep/ .el-tabs--border-card>.el-tabs__content {
    background: #fff !important;
}

/deep/ .el-tabs--border-card {
    background: transparent;
}

.time {
    font-size: 12px;
    color: #999;
}

.bottom {
    margin-top: 3px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    padding: 0;
    min-height: auto;
}

.image {
    width: 100%;
    display: block;
}

/deep/ .el-tabs__content {
    height: calc(100vh - 180px);
    overflow: auto;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.czyform {
    position: absolute;
    left: 0;
    right: 0;
    top: 38px;
    background: #fff;
    z-index: 999;
    padding: 15px;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #409eff
}

.cjtag {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    background: #f1f1f1;
    padding: 2px 7px;
    border-radius: 4px;
    color: #d44c2d;
}
</style>