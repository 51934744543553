<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>表单管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!formId">新增表单</el-breadcrumb-item>
                <el-breadcrumb-item v-if="formId">编辑表单</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 180px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="110px">
                <el-row v-if="!this.formId">
                    <el-col :span="12">
                        <el-form-item label="添加文件" prop="file">
                            <el-upload :auto-upload="false" ref="zdyupload" v-model:file-list="form.file"
                                class="upload-demo" drag @change="handleChange" action="" accept=".pdf">
                                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                                <div class="el-upload__text">
                                    拖到文件到此处或 <em>点击上传文件</em>
                                </div>
                                <template #tip>
                                    <div class="el-upload__tip">
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="文件名称" prop="fileName">
                            {{ form.fileName }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="归属客户" prop="customId">
                            <el-select :disabled="role.includes(1)" clearable @change="changeCustom" v-model="form.customId" placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="应用场景" prop="sceneId">
                            <el-select clearable v-model="form.sceneId" placeholder="请选择场景">
                                <el-option v-for="item in sceneList" :key="item.sceneId" :label="item.sceneName"
                                    :value="item.sceneId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="表单名称" prop="formName">
                            <el-input v-model="form.formName" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="表单业务类型" prop="businessType">
                            <el-radio-group v-model="form.businessType">
                                <el-radio :label="0">普通表单</el-radio>
                                <!-- <el-radio :label="1">明信片</el-radio> -->
                                <el-radio :label="2">群笔记表单</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="表单分类" prop="formType">
                            <el-input v-model="form.formType" placeholder="最多6个字" maxlength="6" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="关联表" prop="relateTables">
                            <el-select clearable v-model="form.relateTablesList" multiple placeholder="请选择关联表"
                                style="width: 100%;">
                                <el-option v-for="item in formRelateList" :key="item.id" :label="item.tableCnName"
                                    :value="item.id + ''" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否设计" prop="isDesign">
                            <el-switch v-model="form.isDesign"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否本册" prop="isBook">
                            <el-switch v-model="form.isBook"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun } from "@/api/adminservice/qun"
import { formCreate, formInfo, updateForm, formRelateTable } from "@/api/adminservice/form"
import { queryScene } from "@/api/adminservice/scene"
import { ElLoading } from 'element-plus'

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            fileList: [],
            formId: '',
            groupData: {},
            id: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                orgId: '',
                file: [],
                isDesign: false,
                isBook:false,
                businessType: 0,
                relateTablesList: []
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            loadingInstance: null,
            // 表单校验
            rules: {
                customId: [
                    { required: true, message: "请选择客户", trigger: "change" },
                ],
                formName: [
                    { required: true, message: "请输入表单名称", trigger: "blur" },
                ],
                businessType: [
                    { required: true, message: "请选择表单业务类型", trigger: "change" },
                ],
                // formType: [
                //     { required: true, message: "请输入表单分类", trigger: "blur" },
                // ],
                sceneId: [
                    { required: true, message: "请选择场景", trigger: "change" },
                ],
                file: [
                    { required: true, message: "请选择文件", trigger: "change" },
                ],
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            orgList: [],
            oldBookIds: [],
            sceneList: [],
            formRelateList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
    },
    mounted() {
        this.getFormRelateTable();
        if (this.$route.query.formId) {
            this.formId = this.$route.query.formId;
            this.form.formId = this.$route.query.formId;
            formInfo({ formId: this.formId }).then(res => {
                if (res.data.data.relateTables) {
                    this.form.relateTablesList = res.data.data.relateTables.split(',')
                } else {
                    this.form.relateTablesList = []
                }

                Object.assign(this.form, res.data.data)
                console.log(this.form.relateTablesList);
                this.getFormRelateTable()
                if (this.form.isDesign == 1) {
                    this.form.isDesign = true
                } else {
                    this.form.isDesign = false
                }
                if(this.form.isBook == 1){
                    this.form.isBook = true
                }else{
                    this.form.isBook = false
                }
                this.getList();

            })

        } else {
            if(this.$storage.get("role").includes(1)){
                this.form.customId = this.$storage.get("customId")
                this.changeCustom()
            }
            this.getList();

        }
    },
    computed: {
    },
    methods: {
        getFormRelateTable() {
            if (this.form.customId) {
                formRelateTable({ customId: this.form.customId }).then(res => {
                    console.log(res);
                    this.formRelateList = res.data.data;
                })
            }
        },
        handleChange(file, fileList) {
            console.log(fileList)
            console.log(fileList.length)

            this.form.file = []
            if (fileList[0]) {
                this.form.file.push(fileList[fileList.length - 1])
                this.form.fileName = fileList[fileList.length - 1].name.substring(0, fileList[fileList.length - 1].name.lastIndexOf("."));
                this.form.formName = fileList[fileList.length - 1].name.substring(0, fileList[fileList.length - 1].name.lastIndexOf("."));
            }
            // console.log(this.form.file)
        },
        changeOrg() {
            console.log(this.$refs['tsd'])
            console.log(this.oldBookIds);
            this.form.bookIds = [];
            customOrgBook({ orgId: this.form.orgId }).then(res => {
                this.bookList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldBookIds.forEach(j => {
                        if (e.id == j) {
                            this.form.bookIds.push(e.id)
                        }
                    })
                })
            })
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.form.adminName = res.data.data.nickName;
                    this.form.adminUserId = res.data.data.userId;

                    callback()
                }
            })
        },
        changeCustom() {
            this.orgList = [];
            this.sceneList = [];
            this.form.sceneId = '';
            this.form.orgId = '';
            this.form.relateTablesList = [];
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
            if (this.form.customId) {
                queryScene({ customId: this.form.customId }).then(res => {
                    this.sceneList = res.data.data;
                    if (this.sceneList.length == 1) {
                        this.form.sceneId = this.sceneList[0].sceneId
                    }
                })
            }

            this.getFormRelateTable()
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                // if(this.customList.length == 1){
                //     this.form.customId = this.customList[0].customId
                // }else{

                // }
            });
            if (this.form.customId) {
                queryScene({ customId: this.form.customId }).then(res => {
                    this.sceneList = res.data.data;
                    if (this.sceneList.length == 1) {
                        this.form.sceneId = this.sceneList[0].sceneId
                    }
                })
            }

        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    this.form.relateTables = this.form.relateTablesList.toString()
                    if (this.formId) {
                        if (this.form.isDesign == false) {
                            this.form.isDesign = 0
                        } else {
                            this.form.isDesign = 1
                        }
                        if (this.form.isBook == false) {
                            this.form.isBook = 0
                        } else {
                            this.form.isBook = 1
                        }
                        updateForm(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/formMain', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        this.loadingInstance = ElLoading.service({
                            lock: true,
                            text: '提交中',
                            background: 'rgba(0, 0, 0, 0.7)',
                        })
                        let formdata = new FormData();
                        this.form.file.map((item) => {
                            console.log(item)
                            formdata.append('file', item.raw)
                        })
                        formdata.append('relateTables', this.form.relateTables)
                        formdata.append('customId', this.form.customId)
                        formdata.append('fileName', this.form.fileName)
                        formdata.append('formName', this.form.formName)
                        formdata.append('businessType', (this.form.businessType || this.form.businessType == 0) ? this.form.businessType : '')
                        formdata.append('formType', this.form.formType ? this.form.formType : '')
                        formdata.append('isDesign', this.form.isDesign == true ? 1 : 0)
                        formdata.append('isBook', this.form.isBook == true ? 1 : 0)
                        formdata.append('sceneId', this.form.sceneId)

                        formCreate(formdata).then(res => {
                            if (res.data.code == 1) {
                                this.loadingInstance.close()
                                this.$message.success("新增成功");
                                this.replace('/formMain', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.loadingInstance.close()
                                this.$message.error("操作失败")
                            }

                        }).catch(err => {
                            this.loadingInstance.close()
                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/formMain', { customId: this.customId, isSx: 1 })
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList

                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>