<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleAdd">新建教辅</el-button>
                    <!-- <el-button type="primary" @click="hadleDz">铺设点阵</el-button> -->
                    <el-button type="primary" @click="hadlePs">设计教辅</el-button>
                    <el-button type="primary" @click="handleXzPs">下载合成点阵文件</el-button>
                    <el-button type="primary" @click="handleXz">下载空白点阵文件</el-button>
                    <el-button type="primary" @click="getList()">刷新</el-button>
                    <!-- <el-button type="primary" @click="handleRelease">发布教辅</el-button> -->
                    <!-- <el-button type="primary" @click="handleStop">取消发布教辅</el-button> -->
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="handleQuery">
                                <el-row class="">
                                    <el-col :span="24" class="">
                                        <el-form-item label="铺设状态" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.printStatus"
                                                placeholder="请选择">
                                                <el-option v-for="item in printStatusList" :key="item.value"
                                                    :label="item.name" :value="item.value" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="" v-if="role.includes(0)">
                                        <el-form-item label="归属客户" class="w-100">
                                            <el-select clearable class="w-100" @change="changeCustom" v-model="queryParams.customId"
                                                placeholder="请选择">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0"
                                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                                        <el-form-item label="学校" prop="schoolId" class="w-100">
                                            <el-select class="w-100" clearable
                                                v-model="queryParams.schoolId" placeholder="请选择学校">
                                                <el-option v-for="item in schoolList" :key="item.schoolId"
                                                    :label="item.schoolName" :value="item.schoolId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="">
                    <el-col :span="8" class="">
                        <el-form-item label="铺设状态">
                            <el-select clearable @change="getList" v-model="queryParams.printStatus" placeholder="请选择">
                                <el-option v-for="item in printStatusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="" v-if="role.includes(0)">
                        <el-form-item label="归属客户">
                            <el-select clearable @change="changeCustom" v-model="queryParams.customId" placeholder="请选择">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="mr0"
                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select style="width: 100%;" clearable @change="getList" v-model="queryParams.schoolId"
                                placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e" style="position: relative;">
                <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt=""> -->
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange" class=""
                    :height="isSxShow ? 'calc(100vh - 255px)' : 'calc(100vh - 255px)'" v-loading="loading" :data="dataList"
                    highlight-current-row>
                    <!-- <el-table-column align="center" type="selection" width="55" /> -->
                    <el-table-column align="center" type="index" label="序号" width="60" />
                    <el-table-column align="center" width="90" label="铺设状态" prop="printStatus">
                        <template #default="scope">
                            <div v-if="scope.row.printStatus == 0">未铺设</div>
                            <div v-if="scope.row.printStatus == 1">铺设中</div>
                            <div v-if="scope.row.printStatus == 2">铺设成功</div>
                            <div v-if="scope.row.printStatus == 3">铺设失败</div>
                            <div v-if="scope.row.printStatus == 4">无需铺设</div>

                            <!-- <div v-if="scope.row.status == 4">发布</div>
                        <div v-if="scope.row.status == 5">停用</div> -->
                        </template>
                    </el-table-column>
                    <!-- <el-table-column align="center" width="90" label="流程状态" prop=" status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">待处理</div>
                            <div v-if="scope.row.status == 1">已完成</div>
                            <div v-if="scope.row.status == 2">处理失败</div>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column align="center" fixed="left" width="170" label="编号" prop="formNo" />
                <el-table-column align="center" width="200" label="文件名称" prop="fileName" /> -->
                    <el-table-column align="center" width="380" label="文件名称" prop="fileName">
                        <template #default="scope">
                            <div :title="scope.row.fileName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.fileName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="60" label="页数" prop="pages" />
                    <el-table-column align="center" width="180" label="年级" prop="grade" />
                    <el-table-column align="center" width="350" label="学校" prop="schoolName" sortable />
                    <el-table-column align="center" width="220" v-if="role.includes(0)" label="归属客户" prop="customName"
                        sortable>
                        <template #default="scope">
                            <div :title="scope.row.customName" class="text-one">
                                {{ scope.row.customName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="创建时间" prop="createTime" sortable>
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.createTime) }}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column align="center" width="140" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column align="center" width="140" label="停用时间" prop="stopTime">
                    <template #default="scope">
                        <div>{{ timestampToTime(scope.row.stopTime) }}</div>
                    </template>
                </el-table-column> -->
                    <!-- <el-table-column align="center" width="150" label="点阵地址" prop="startPageAddress" /> -->

                    <el-table-column align="center" fixed="right" width="160" label="编辑"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <!-- <el-button size="mini" type="text" icon="el-icon-view" @click="goDetail(scope.row)">查看
                        </el-button> -->
                            <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                            </el-button>

                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该教辅" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button link icon="Delete" type="danger">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" label="操作" width="140">
                        <template #default="scope">
                            <el-button link type="primary" @click="handleCopy(scope.row)">复制</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog title="复制到学校" v-model="copyShow" width="60%">
            <el-form ref="reportForm" label-width="100px" v-loading="scloading">
                <el-row>
                    <el-form-item label="学校">
                        <el-select style="width: 100%;" clearable v-model="copyScObj.schoolId" placeholder="请选择学校">
                            <el-option v-for="item in schoolcpList" :key="item.schoolId" :label="item.schoolName"
                                :value="item.schoolId" />
                        </el-select>
                    </el-form-item>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center disnone" style="align-items: unset;">
                    <el-button v-if="copyScObj.schoolId" type="primary" @click="copyScSub">确 定</el-button>
                    <el-button class="ml15" @click="copyShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms } from "@/api/adminservice/custom";
import { formQuery, deleteForm, formRelease, formStop, print } from "@/api/adminservice/form"
import { download } from "@/api/adminservice/public"
import { queryScene } from "@/api/adminservice/scene"
import { eduFileList, eduFileDel, eduFileCopy, eduFileDelById } from "@/api/adminservice/readOver"
import { schoolListAuth } from "@/api/adminservice/classOrganizaMain";

export default {
    name: "teachAssistantMain",
    components: {
    },
    data() {
        return {
            visible: false,
            scloading: false,
            copyShow: false,
            copyScObj: {},
            schoolcpList: [],
            schoolList: [],
            chapterData: [],
            customList: [],
            processStatusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
                { name: '已停用', value: 2 },
            ],
            printStatusList: [
                { name: '未铺设', value: 0 },
                { name: '铺设中', value: 1 },
                { name: '铺设成功', value: 2 },
                { name: '铺设失败', value: 3 },
                { name: '无需铺设', value: 4 },
                // { name: '发布', value: 4 },
                // { name: '停用', value: 5 },
            ],
            sceneList: [],
            timestampToTime,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isSxShow: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                schoolId: '',
                printStatus: '',
                businessType: '',
                screenName: '',
                status: '',
                formType: '',
                sceneId: '',
                isDesign: '',
                customId: '',
                createTime: [],
                releaseTime: []
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getList();
        this.getSceneList()
        this.getZhktQueryDict();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        getZhktQueryDict() {
            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
            });
        },
        hadleDz() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.processStatus == 1 || this.selectObj.processStatus == 2) {
                this.$message.error("发布和停用的数据不能铺设");
                return;
            }
            print({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("铺设成功");
                    this.getList();
                } else {
                    this.$message.error("铺设失败");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        getSceneList() {
            this.customList = []
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
            });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('040');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息技术有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
            });
        },
        changeCustom() {
            this.queryParams.sceneId = '';
            queryScene().then(res => {
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                    })
                }
                this.sceneList = res.data.data;
            });
            // this.getList()
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.dataList = [];
            console.log(this.queryParams.createTime)
            console.log(this.queryParams.releaseTime)
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            this.loading = false;

            eduFileList(this.queryParams).then(res => {
                if (this.queryParams.printStatus !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.printStatus == this.queryParams.printStatus
                    })
                }
                if (this.queryParams.status !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.status.toString().indexOf(this.queryParams.status) != -1
                    })
                }
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                    })
                }
                this.dataList = res.data.data;
                this.dataList.sort(function (a, b) {
                    if (a.createTime > b.createTime) { return -1; }
                    if (a.createTime < b.createTime) { return 1; }
                    return 0;
                })
            });
        },

        // 停用
        handleStop(row) {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.processStatus != 1) {
                this.$message.error("请选择已发布的数据");
                return;
            }
            formStop({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        handleRelease() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.status == 0) {
                this.$message.error("未铺设的数据不能发布");
                return;
            }
            formRelease({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleCopy(row) {
            this.schoolcpList = []
            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolcpList = res.data.data;
                this.copyScObj = {
                    fileId: row.fileId,
                    schoolId: '',
                }
                this.copyShow = true;
                // this.getList();
            });
        },
        copyScSub() {
            this.scloading = true;
            eduFileCopy(this.copyScObj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("复制成功");
                    this.copyShow = false;
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
                this.scloading = false;
            })
        },
        handleXz() {
            if (!this.selectObj || !this.selectObj.fileId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.blankDotFileUrl) {
                var fileName = this.selectObj.fileName + '.pdf'
                download(this.selectObj.blankDotFileUrl, this.selectObj.fileName + '空白点阵文件')
            } else {
                this.$message.error("当前文件没有空白点阵文件");
            }
            // if (!this.selectObj || !this.selectObj.formId) {
            //     this.$message.error("请选择数据");
            //     return;
            // }
            // var fileName = this.selectObj.formName + '.pdf'
            // download(this.selectObj.formUrl + '/original.pdf', this.selectObj.formName)
        },
        handleXzPs() {
            console.log(this.selectObj)
            if (!this.selectObj || !this.selectObj.fileId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.printStatus != 2) {
                this.$message.error("请选择铺设成功的数据");
                return;
            } else {

            }
            var fileName = this.selectObj.fileName + '_dot.pdf'
            download(this.selectObj.dotFileUrl, fileName)
        },
        hadlePs() {
            console.log(process.env.NODE_ENV);
            if (!this.selectObj || !this.selectObj.fileId) {
                this.$message.error("请选择数据");
                return;
            }
            // if (process.env.NODE_ENV === "production") {
            //     console.log(this.selectObj)
            //     let routeUrl = this.$router.resolve({
            //         path: '/ZBDesigner',// 这里的路径就可以正常的写，不需要添加_blank: true
            //         query: { userId: JSON.parse(this.$storage.get('userInfo')).userId, fileId: this.selectObj.fileId }
            //     })
            //     window.open(routeUrl.href, '_blank')
            // } else {
            console.log(this.selectObj)
            let routeUrl = this.$router.resolve({
                path: '/teachAssistantDesignView',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userId: JSON.parse(this.$storage.get('userInfo')).userId, fileId: this.selectObj.fileId }
            })
            window.open(routeUrl.href, '_blank')
            // }


            // var url = "http://192.168.101.116:8080/?userId=" + JSON.parse(this.$storage.get('userInfo')).userId + "&formId=" + this.selectObj.formId
            // window.open(url)
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            // if (row.isCopy == 0) {
            //     eduFileDel({ originalFileUrl: row.originalFileUrl }).then(res => {
            //         if (res.data.code == 1) {
            //             this.$message.success("删除成功");
            //             this.getList();
            //         } else {
            //             this.$message.error("删除失败");
            //         }
            //     })
            // } else {
            eduFileDelById({ fileId: row.fileId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
            // }

        },
        goDetail(row) {
            this.$router.push({
                path: "/teachAssistantDetail",
                query: {
                    fileId: row.fileId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/teachAssistantAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/teachAssistantAdd",
                query: {
                    fileId: row.fileId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>