<template>
    <div>
        <div tabindex="-1" class="el-image-viewer__wrapper" style="z-index: 2201;right: 220px !important;">
            <div class="el-image-viewer__mask"></div><!-- CLOSE --><span @click="closedd"
                class="el-image-viewer__btn el-image-viewer__close"><i class="el-icon"><svg viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                    </svg></i></span>
            <!-- CANVAS -->
            <div class="el-image-viewer__canvas">
                <div ref="codeImgRef" class="dcCanvas"
                    :style="'height:' + canvasHeight + 'px;width:' + canvasWidth + 'px;position: absolute;'"
                    v-for="(item, index) in strokeList.length">

                    <img v-if="index == (currentNum - 1)"
                        :style="'pointer-events: none;position:absolute;left:0;border:1px solid #eeeeee;border-radius:10px;width:' + canvasWidth + 'px;height:' + canvasHeight + 'px;'"
                        crossOrigin="anonymous" :src="bookData.formUrl + '/' + (index + 1) + '.png?_=' + Date.now()">
                    <canvas v-if="index == (currentNum - 1)" :width="canvasWidth * smScale" :height="canvasHeight * smScale"
                        type="2d" :id="'mineCanvas' + index"
                        :style="'pointer-events: none;position: absolute;left:0;width:' + canvasWidth + 'px;height:' + canvasHeight + 'px;z-index: 1000;'">
                    </canvas>
                    <div v-if="isShow" v-for="items in formDesignData"
                        :style="'width: ' + items.w0 + 'px;height: ' + items.h0 + 'px;position: absolute;left: ' + items.x0 + 'px;top: ' + items.y0 + 'px;z-index:999;pointer-events: none;overflow:hidden;font-size:18px;text-align:left;'">
                        {{ items.value }}
                    </div>
                </div>
            </div>
            <div class="pofixedBox" style="z-index: 999999;right: 220px;">
                <div @click="changeStop" class="leftImg">
                    <img v-if="isStop" src="../assets/img/play.png">
                    <img v-else src="../assets/img/pause.png">
                </div>
                <el-slider @change="change" :show-tooltip="false" :min="0" :max="progressLength" disabled
                    v-model="progress" />
                <!-- <u-slider :activeColor="'#d54d2f'" @changing="changing" @change="change" style="flex: 1;" v-model="progress"
                    min="0" :max="progressLength" :blockSize="10" block-width="40" block-color="red"></u-slider> -->
                <div @click="changeSpeed" class="cursor" style="padding-right: 15px;padding-left: 15px;color: #fff;">
                    {{ speed }}
                </div>
            </div>
            <div style="position: fixed;right: 0;top: 0;bottom:0;width: 220px;background: #fff;">
                <el-table :data="strokeList[currentNum - 1].strokeList" style="width: 100%" height="100vh">
                    <el-table-column align="center" type="index" label="序号" width="60" />
                    <el-table-column align="center" prop="name" label="书写时间" width="160">
                        <template #default="scope">
                            <div :style="bfSt==scope.row.st?'color:red;':''">{{ timestampToS(scope.row.st) }}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>

import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import { timestampToTime,timestampToS } from "@/utils/date";

export default {
    name: 'canvasWriteTablePlay',
    props: {
        strokeList: {
            type: Array,
            default: []
        },
        bookData: {
            type: Object,
            default: {}
        },
        currentNum: {
            type: Number,
            default: 1
        },
        formDesignData: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            timestampToTime,
            timestampToS,
            canvasHeight: 0,
            scale: 1,
            smScale: 2,
            current: 0,
            ctxList: [],
            clickPage: [],
            isShow: false,
            isStop: false,
            progress: 0,
            progressLength: 0,
            clearTimeoutP: null,
            isChanging: 0,
            isNum: 0,
            speedList: ['1.0X', '1.5X', '2.0X', '2.5X'],
            speed: '1.0X',
            isSow: false,
            bfSt:'',
        }
    },
    created() {
        // 监听网页大小
        // this.bookData = JSON.parse(this.$storage.get("zsdata"));
        // this.strokeList = JSON.parse(this.$storage.get("zsdata")).strokeList;
    },
    mounted() {
        this.canvasHeight = window.innerHeight * (8.5 / 10);
        console.log(this.formDesignData);

        this.canvasWidth = this.canvasHeight * this.bookData.pageWidth / this.bookData.pageHeight;
        this.scale = (this.canvasWidth / this.bookData.pageWidth / 8 * 0.3)
        setTimeout(() => {
            this.formDesignData.forEach((e, i) => {
                e.x0 = e.x / this.bookData.pageWidth * this.canvasWidth;
                e.y0 = e.y / this.bookData.pageHeight * this.canvasHeight;
                e.w0 = e.width / this.bookData.pageWidth * this.canvasWidth;
                e.h0 = e.height / this.bookData.pageHeight * this.canvasHeight;
            })
            setTimeout(() => {
                this.isShow = true
            })

            this.startCanvas()
        })

    },
    methods: {
        changeSpeed() {
            for (var i = 0; i < this.speedList.length; i++) {
                if (this.speed === this.speedList[i]) {
                    if (i === this.speedList.length - 1) {
                        this.speed = this.speedList[0]
                    } else {
                        this.speed = this.speedList[i + 1]
                    }
                    break;
                }

            }
        },
        changeStop() {
            this.isStop = !this.isStop;
            if (this.progressLength == this.progress) {
                this.progress = 0;
                this.ctxList[this.currentNum - 1].clearRect(0, 0, this.canvasWidth * this.smScale, this.canvasHeight * this.smScale);
                this.canvasToStrokePointList();
            }
        },
        stopcanvasToStrokePointList() {
            // let list = this.emptyRepeatStroke(this.strokeList);
            this.progressLength = this.strokeList.length;
            for (var k = 0; k < this.strokeList.length; k++) {
                let j = this.strokeList[k];
                if (j.n != null) {
                    this.ctxList[this.currentNum - 1].strokeStyle = this.$store.state.zdy.activeColor;
                    this.ctxList[this.currentNum - 1].lineWidth = (this.$store.state.zdy.activeLineWidth / this.bookData.pageWidth * this.canvasWidth / 3);
                    // console.log(index, k, j.p, j.n)
                    this.stopgetPoints(k, j.p)
                }
                if (this.isNum != this.isChanging) {
                    break;
                }
                if (k == this.progress) {
                    break;
                }
            }
        },
        // 笔迹转点
        stopgetPoints(n, svgPointsPath) {
            if (this.strokeList[n].stList) {
                this.stopdrowStroke(this.strokeList[n].stList)
            } else {
                console.log(1)
                var points = [];
                var dataArray = svgPointsPath.split("l");
                if (dataArray.length == 2) {
                    var firstPontArray = dataArray[0].replace(/M/, "").split(" ");
                    // console.log('firstPontArray', firstPontArray)
                    var firstPointX = Math.round(firstPontArray[0].trim());
                    var firstPointY = Math.round(firstPontArray[1].trim());
                    // console.log('firstPointX', firstPointX, firstPointY)
                    points.push({
                        x: firstPointX,
                        y: firstPointY
                    })
                    var pointsArray = dataArray[1].replace("l", "").replace(/-/g, " -").trim().split(" ");
                    for (var i = 0; i < pointsArray.length; i++) {
                        if ((i + 1) < pointsArray.length && i % 2 == 0) {
                            firstPointX = Math.round(firstPointX) + Math.round(pointsArray[i].trim());
                            firstPointY = Math.round(firstPointY) + Math.round(pointsArray[i + 1].trim());
                            points.push({
                                x: firstPointX,
                                y: firstPointY
                            })
                        }

                    }
                }
                this.strokeList[n].stList = points;
                this.stopdrowStroke(points)
            }

        },
        copyCodeImg() {
            let pdf = new jsPDF('p', 'pt', 'a4');
            let uuid = this.uuid()
            this.$refs.codeImgRef.forEach((e, i) => {
                html2canvas(e, {
                    backgroundColor: "#ffffff",
                    useCORS: true,
                }).then(async canvas => {
                    let pageData = canvas.toDataURL('image/jpeg');
                    // // 生成图片地址
                    // var imgUrl = canvas.toDataURL('image/png');
                    // // 创建一个a标签，用来下载图片
                    // var eleLink = document.createElement('a');
                    // // // 转换后的图片地址
                    // eleLink.href = imgUrl;
                    // // // 文件名设置
                    // eleLink.download = '默认文件名';
                    // // // 下载
                    // eleLink.click();
                    pdf.addImage(pageData, 'JPEG', 0, 0, this.canvasWidth, this.canvasHeight)
                    if (i == this.strokeList.length - 1) {
                        console.log(112233)
                        pdf.save(this.bookData.fileName + '-' + timestampToTime(this.bookData.createTime) + '.pdf');
                    } else {
                        pdf.addPage()
                    }
                })
            })
            this.$emit('canvasClose', true)
        },

        uuid() {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4";   // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);   // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";
            return s.join("");
        },
        closedd() {
            clearTimeout(this.clearTimeoutP);
            this.$emit('canvasClose', true)
        },
        lastPage() {
            if (this.current > 0) {
                this.current--;
                this.translateX = this.current * this.swiperWidth;
                if (this.clickPage.indexOf(this.current) === -1) {
                    this.canvasToStrokePointList(this.current)
                }
            }
        },
        nextPage() {
            if (this.current < this.strokeList.length - 1) {
                this.current++;
                this.translateX = this.current * this.swiperWidth;
                if (this.clickPage.indexOf(this.current) === -1) {
                    this.canvasToStrokePointList(this.current)
                }
            }
        },
        // 监听网页大小
        watchWidth() {
        },
        // 画布初始化
        startCanvas() {
            console.log(1, this.currentNum);
            console.log(2, this.strokeList);
            console.log(3, this.bookData);
            // for (var i = 0; i < this.strokeList.length; i++) {
            var canvas = document.getElementById("mineCanvas" + (this.currentNum - 1));
            // 获取在 canvas 上绘图的 canvasRenderingContent2D 对象
            var ctx = canvas.getContext("2d");
            this.ctxList[this.currentNum - 1] = ctx;
            this.ctxList[this.currentNum - 1].strokeStyle = this.$store.state.zdy.activeColor;
            this.ctxList[this.currentNum - 1].lineWidth = (this.$store.state.zdy.activeLineWidth / this.bookData.pageWidth * this.canvasWidth / 3);
            this.ctxList[this.currentNum - 1].lineCap = 'round';
            this.ctxList[this.currentNum - 1].lineJoin = 'round';
            // }
            setTimeout(() => {
                // for (var i = 0; i < this.strokeList.length; i++) {
                this.canvasToStrokePointList(this.currentNum - 1)
                // }
            }, 1000)
        },
        //Canvas字符串转化为笔迹点列表
        canvasToStrokePointList(index) {
            try {
                this.clickPage.push(index)
                let list = this.emptyRepeatStroke(this.strokeList[this.currentNum - 1].strokeList);
                this.progressLength = list.length;
                let that = this;
                setTimeout(function go_play() {
                    
                    if (that.progress === that.progressLength) {
                        that.isStop = true
                        clearTimeout(that.clearTimeoutP);
                        return
                    }
                    let j = that.strokeList[that.currentNum - 1].strokeList[that.progress];
                    that.bfSt = j.st
                    // console.log(that.bfSt);
                    if (j.p != null) {
                        if (j.c) {
                            that.ctxList[that.currentNum - 1].strokeStyle = (j.c)
                        } else {
                            that.ctxList[that.currentNum - 1].strokeStyle = that.$store.state.zdy.activeColor;
                        }
                        that.ctxList[that.currentNum - 1].lineWidth = (that.$store.state.zdy.activeLineWidth / that.bookData.pageWidth * that.canvasWidth / 3);
                        // console.log(index, k, j.p, j.n)
                        that.getPoints(that.progress, j.p)
                    } !that.isStop && ++that.progress;
                    if (that.speed === '1.0X') {
                        that.clearTimeoutP = setTimeout(go_play, 200)
                    } else if (that.speed === '1.5X') {
                        that.clearTimeoutP = setTimeout(go_play, 100)
                    } else if (that.speed === '2.0X') {
                        that.clearTimeoutP = setTimeout(go_play, 50)
                    } else if (that.speed === '2.5X') {
                        that.clearTimeoutP = setTimeout(go_play, 10)
                    }
                }, 100)
                // list.forEach((j, k) => {
                //     this.ctxList[this.currentNum - 1].strokeStyle = this.$store.state.zdy.activeColor;
                //     this.ctxList[this.currentNum - 1].lineWidth = (this.$store.state.zdy.activeLineWidth / this.bookData.pageWidth * this.canvasWidth / 3);
                //     this.getPoints(index, k, j.p, index)
                // })
            } catch (e) {
                //TODO handle the exception
                console.log('e', e)
            }
        },
        // 去除重复笔迹
        emptyRepeatStroke(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.p) && res.set(arr.p, 1))
        },
        // 笔迹转点
        getPoints(n, svgPointsPath) {
            if (this.strokeList[this.currentNum - 1].strokeList[n].stList) {
                this.drowStroke(this.strokeList[this.currentNum - 1].strokeList[n].stList)
            } else {
                // console.log(1)
                var points = [];
                var dataArray = svgPointsPath.split("l");
                if (dataArray.length == 2) {
                    var firstPontArray = dataArray[0].replace(/M/, "").split(" ");
                    // console.log('firstPontArray', firstPontArray)
                    var firstPointX = Math.round(firstPontArray[0].trim());
                    var firstPointY = Math.round(firstPontArray[1].trim());
                    // console.log('firstPointX', firstPointX, firstPointY)
                    points.push({
                        x: firstPointX,
                        y: firstPointY
                    })
                    var pointsArray = dataArray[1].replace("l", "").replace(/-/g, " -").trim().split(" ");
                    for (var i = 0; i < pointsArray.length; i++) {
                        if ((i + 1) < pointsArray.length && i % 2 == 0) {
                            firstPointX = Math.round(firstPointX) + Math.round(pointsArray[i].trim());
                            firstPointY = Math.round(firstPointY) + Math.round(pointsArray[i + 1].trim());
                            points.push({
                                x: firstPointX,
                                y: firstPointY
                            })
                        }

                    }
                }
                this.strokeList[this.currentNum - 1].strokeList[n].stList = points;
                this.drowStroke(points)
            }

            // var points = [];
            // var dataArray = svgPointsPath.split("l");
            // if (dataArray.length == 2) {
            //     var firstPontArray = dataArray[0].replace(/M/, "").split(" ");
            //     var firstPointX = Math.round(firstPontArray[0].trim());
            //     var firstPointY = Math.round(firstPontArray[1].trim());
            //     points.push({
            //         x: firstPointX,
            //         y: firstPointY
            //     })
            //     var pointsArray = dataArray[1].replace("l", "").replace(/-/g, " -").trim().split(" ");
            //     for (var i = 0; i < pointsArray.length; i++) {
            //         if ((i + 1) < pointsArray.length && i % 2 == 0) {
            //             firstPointX = Math.round(firstPointX) + Math.round(pointsArray[i].trim());
            //             firstPointY = Math.round(firscanvasToStrokePointListtPointY) + Math.round(pointsArray[i + 1].trim());
            //             points.push({
            //                 x: firstPointX,
            //                 y: firstPointY
            //             })
            //         }

            //     }
            // }
            // this.strokeList[m].strokeList[n].stList = points;
            // this.drowStroke(points, pageID)
        },
        changing(e) {
            console.log(e)
            this.isStop = true;
            // if(e!=this.progress){
            // 	this.isChanging++;
            // 	this.progress = e;
            // 	this.stopcanvasToStrokePointList();
            // }

            // console.log(e)
        },
        change(e) {
            console.log(e)
            this.isStop = false;
            this.progress = e;
            this.ctxList[this.currentNum - 1].clearRect(0, 0, this.canvasWidth * this.smScale, this.canvasHeight * this.smScale);
            this.stopcanvasToStrokePointList();
            clearTimeout(this.clearTimeoutP);
            this.canvasToStrokePointList()
        },
        // 点集绘制笔记
        stopdrowStroke(pointList, pageID) {
            this.ctxList[pageID].beginPath();

            let pointLast = {};
            let pointNext = {};
            if (pointList.length > 1) {
                for (var i = 0; i < pointList.length; i++) {
                    if ((i + 1) < pointList.length) {
                        pointLast = pointList[i];
                        pointNext = pointList[i + 1];
                    } else {
                        pointLast = pointList[i - 1];
                        pointNext = pointList[i];
                    }
                    // 笔记粗细
                    // this.ctx2.lineWidth(1);
                    let pX1 = pointLast.x * this.scale * this.smScale;
                    let pY1 = pointLast.y * this.scale * this.smScale;
                    let pX2 = pointNext.x * this.scale * this.smScale;
                    let pY2 = pointNext.y * this.scale * this.smScale;
                    if (i === 0) {
                        this.ctxList[pageID].moveTo(pX1, pY1);
                    } else {
                        // 笔迹
                        this.ctxList[pageID].moveTo(pX1, pY1);
                        this.ctxList[pageID].lineTo(pX2, pY2);
                    }
                }
            }
            this.ctxList[pageID].stroke();
        },
        // 点集绘制笔记
        drowStroke(pointList, pageID) {
            this.ctxList[this.currentNum - 1].beginPath();

            let pointLast = {};
            let pointNext = {};
            if (pointList.length > 1) {
                for (var i = 0; i < pointList.length; i++) {
                    if ((i + 1) < pointList.length) {
                        pointLast = pointList[i];
                        pointNext = pointList[i + 1];
                    } else {
                        pointLast = pointList[i - 1];
                        pointNext = pointList[i];
                    }
                    // 笔记粗细
                    // this.ctx2.lineWidth(1);
                    let pX1 = pointLast.x * this.scale * this.smScale;
                    let pY1 = pointLast.y * this.scale * this.smScale;
                    let pX2 = pointNext.x * this.scale * this.smScale;
                    let pY2 = pointNext.y * this.scale * this.smScale;
                    if (i === 0) {
                        this.ctxList[this.currentNum - 1].moveTo(pX1, pY1);
                    } else {
                        // 笔迹
                        this.ctxList[this.currentNum - 1].moveTo(pX1, pY1);
                        this.ctxList[this.currentNum - 1].lineTo(pX2, pY2);
                    }
                }
            }
            this.ctxList[this.currentNum - 1].stroke();
        },
    }
}
</script>

<style scoped>
.bgbbb {
    /* background-color: #fff; */
    z-index: 999;
    position: absolute;
}

.op0 {
    opacity: 0;
}

.op1 {
    opacity: 1;
}

.pofixedBox {
    position: fixed;
    bottom: 0px;
    padding-bottom: 25px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;


}

.pofixedBox .leftImg {
    padding-left: 20px;
    padding-right: 20px;
}

.pofixedBox .leftImg img {
    width: 20px;
}

/deep/ .el-slider__runway.is-disabled .el-slider__bar {
    background-color: #21ac74;
}
</style>