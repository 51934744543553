<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleAdd">创建活动</el-button>
                    <!-- <el-button type="primary" @click="hadleCopy">复制活动</el-button> -->
                    <el-button type="primary" @click="handleFx">停止/恢复分享</el-button>
                    <el-button type="primary" @click="handleRelease">发布/取消发布</el-button>
                    <el-button type="primary" @click="handleStop">结束/取消结束</el-button>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px">
                                <el-row class="mt15">
                                    <el-col :span="24" class="" v-if="role.includes(0)">
                                        <el-form-item label="归属客户" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.customId"
                                                placeholder="请选择">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="状态" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.status"
                                                placeholder="请选择">
                                                <el-option label="初始" :value="0" />
                                                <el-option label="发布" :value="1" />
                                                <el-option label="结束" :value="2" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="发布时间" class="w-100">
                                            <el-date-picker style="width: 100%;" value-format="x"
                                                v-model="queryParams.releaseTime" type="daterange" range-separator="至"
                                                start-placeholder="开始日期" end-placeholder="结束日期" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="结束时间" class="w-100">
                                            <el-date-picker style="width: 100%;" value-format="x"
                                                v-model="queryParams.endTime" type="daterange" range-separator="至"
                                                start-placeholder="开始日期" end-placeholder="结束日期" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card shadow="never">
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row class="mt15">
                    <el-col :span="8" class="" v-if="role.includes(0)">
                        <el-form-item label="归属客户">
                            <el-select clearable @change="getList()" v-model="queryParams.customId" placeholder="请选择">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option label="初始" :value="0" />
                                <el-option label="发布" :value="1" />
                                <el-option label="结束" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="发布时间">
                            <el-date-picker style="width: 100%;" @change="getList()" value-format="x"
                                v-model="queryParams.releaseTime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="结束时间">
                            <el-date-picker style="width: 100%;" @change="getList()" value-format="x"
                                v-model="queryParams.endTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e" style="position: relative;">
                <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt="">
                <el-table header-cell-class-name="bgf2" @current-change="handleCurrentChange" highlight-current-row
                    class="sssstt" :height="'calc(100vh - ' + (isSxShow ? '310' : '315') + 'px)'" v-loading="loading"
                    :data="dataList">
                    <el-table-column align="center" width="70" label="状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">
                                初始
                            </div>
                            <div v-if="scope.row.status == 1">
                                发布
                            </div>
                            <div v-if="scope.row.status == 2">
                                停用
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="活动名称" prop="activityName">
                        <template #default="scope">
                            <div :title="scope.row.activityName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.activityName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="活动地点" prop="activityAddress">
                        <template #default="scope">
                            <div :title="scope.row.activityAddress" class="text-one">
                                {{ scope.row.activityAddress }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="标题" prop="activityTitle">
                        <template #default="scope">
                            <div :title="scope.row.activityTitle" class="text-one">
                                {{ scope.row.activityTitle }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="副标题" prop="activitySubTitle">
                        <template #default="scope">
                            <div :title="scope.row.activitySubTitle" class="text-one">
                                {{ scope.row.activitySubTitle }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="60" label="分享" prop="username">
                        <template #default="scope">
                            <div>
                                {{ scope.row.isShare == 1 ? 'Y' : 'N' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="所属客户" prop="customName">
                        <template #default="scope">
                            <div :title="scope.row.customName" class="text-one">
                                {{ scope.row.customName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="授权用户" prop="memberListTxt">
                        <template #default="scope">
                            <div :title="scope.row.memberListTxt" class="text-one">
                                {{ scope.row.memberListTxt }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="创建时间" prop="createTime" sortable>
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.createTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="发布时间" prop="releaseTime" sortable>
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="结束时间" prop="endTime" sortable>
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.endTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" width="160" label="编辑"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该活动" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button link icon="Delete" type="danger">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" width="420" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button size="mini" type="primary" @click="handlePic(scope.row)">图片素材
                            </el-button>
                            <el-button size="mini" type="primary" @click="handlePerview(scope.row)">笔迹页面
                            </el-button>
                            <el-button size="mini" type="primary" @click="handleDp(scope.row)">活动共享
                            </el-button>
                            <el-button size="mini" type="primary" @click="handleShare(scope.row)">查看分享
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
        <el-dialog draggable :title="'图片素材'" v-model="fileShow" width="90%">
            <el-row class="ml15">
                <el-col :span="16">
                    <el-radio-group v-model="showFileType" size="mini">
                        <el-radio-button label="图片素材" />
                        <el-radio-button label="分享图片" />
                    </el-radio-group>
                </el-col>
            </el-row>
            <div v-if="showFileType == '图片素材'" class="fl-warp mt15" style="display: flex;max-height:60vh;overflow-y: auto;">
                <div v-for="(item, index) in showFileList" class="csbbb" style="position: relative;">
                    <el-icon :title="item.isTheme == 1 ? '取消主题图片' : '设置主题图片'" @click="zdZhu(item, index)" :size="20"
                        class="zhuti" :style="`cursor: pointer;z-index: 99;color: ${item.isTheme == 1 ? 'red' : ''}`">
                        <MessageBox />
                    </el-icon>
                    <el-icon title="置顶" @click="zdTop(item.fileId, index)" :size="20" class="Top"
                        :style="`cursor: pointer;z-index: 99;`">
                        <Top />
                    </el-icon>
                    <el-icon title="分享" @click="sharePicId(item.fileId, index)" :size="20" class="share"
                        :style="`cursor: pointer;z-index: 99;color: ${item.isShare == 1 ? 'red' : ''}`">
                        <Share />
                    </el-icon>
                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled" icon-color="#626AEF"
                        title="确定删除该图片素材" @confirm="delPicId(item.fileId, index)">
                        <template #reference>
                            <el-icon title="删除" :size="20" class="eicon" style="cursor: pointer;z-index: 99;">
                                <Delete />
                            </el-icon>
                        </template>
                    </el-popconfirm>
                    <el-image fit="cover" :initial-index="index" :preview-src-list="showUrlList"
                        :style="`width: 180px;height:130px;margin-bottom: 10px;object-fit: cover;`" :src="item.fileUrl"
                        alt="" class="image" />
                    <div
                        :style="`position: absolute;border:${item.isShare == 1 ? 4 : 0}px solid red;left: 15px;right: 15px;bottom: 10px;top: 0;`">

                    </div>
                </div>
                <div class="csbbb">
                    <el-upload v-model:file-list="filesList" class="avatar-uploader" :auto-upload="false" multiple
                        action="https://zbh.zbform.com/adminservice/zbhsign/activityFile/upload" :show-file-list="false"
                        :on-change="handleSCChange" :before-upload="beforeAvatarUpload" :headers="{ token }"
                        :data="{ activityId: selectCzObj.activityId }">
                        <el-icon style="width: 180px;height:130px;" class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                </div>
            </div>
            <div v-if="showFileType == '分享图片'" class="fl-warp mt15" style="display: flex;max-height:60vh;overflow-y: auto;">
                <div v-show="item.isShare == 1" v-for="(item, index) in showFileList" class="csbbb"
                    style="position: relative;">
                    <el-icon :title="item.isTheme == 1 ? '取消主题图片' : '设置主题图片'" @click="zdZhu(item, index)" :size="20"
                        class="zhuti" :style="`cursor: pointer;z-index: 99;color: ${item.isTheme == 1 ? 'red' : ''}`">
                        <MessageBox />
                    </el-icon>
                    <el-icon title="置顶" @click="zdTop(item.fileId, index)" :size="20" class="Top"
                        :style="`cursor: pointer;z-index: 99;`">
                        <Top />
                    </el-icon>
                    <el-icon title="分享" @click="sharePicId(item.fileId, index)" :size="20" class="share"
                        :style="`cursor: pointer;z-index: 99;color: ${item.isShare == 1 ? 'red' : ''}`">
                        <Share />
                    </el-icon>
                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled" icon-color="#626AEF"
                        title="确定删除该图片素材" @confirm="delPicId(item.fileId, index)">
                        <template #reference>
                            <el-icon title="删除" :size="20" class="eicon" style="cursor: pointer;z-index: 99;">
                                <Delete />
                            </el-icon>
                        </template>
                    </el-popconfirm>
                    <el-image fit="cover" :initial-index="index" :preview-src-list="showUrlList"
                        :style="`width: 180px;height:130px;margin-bottom: 10px;object-fit: cover;`" :src="item.fileUrl"
                        alt="" class="image" />
                    <div
                        :style="`position: absolute;border:${item.isShare == 1 ? 4 : 0}px solid red;left: 15px;right: 15px;bottom: 10px;top: 0;`">

                    </div>
                </div>
                <div class="csbbb">
                    <el-upload v-model:file-list="filesList" class="avatar-uploader" :auto-upload="false" multiple
                        action="https://zbh.zbform.com/adminservice/zbhsign/activityFile/upload" :show-file-list="false"
                        :on-change="handleSCChange" :before-upload="beforeAvatarUpload" :headers="{ token }"
                        :data="{ activityId: selectCzObj.activityId }">
                        <el-icon style="width: 180px;height:130px;" class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                </div>
            </div>
            <!-- <div style="width: 100%;" v-if="showFileList.length == 0">
                <el-empty class="qun-empty" description="暂无证书" />
            </div> -->
        </el-dialog>
        <el-dialog @close="gbIfram" :title="'查看分享'" v-model="isShare" width="500px" top="50px">
            <iframe id="ckpxpfx" style="width: 100%;height:calc(100vh - 250px)" :src="shareUrl" frameborder="0"></iframe>
        </el-dialog>
        <perviewSiginCanvas :currentNum="1" :strokeList="selectCzObj.writeList" :bookData="selectCzObj.book"
            @canvasClose="canvasClPerview" v-if="isPerview">
        </perviewSiginCanvas>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import perviewSiginCanvas from "@/components/perviewSiginCanvas.vue"
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { activityInfo, activityFileUpload, activityCreate, activityDelete, activityList, activityReleaseOrCancel, activityShareOrCancel, activityStopOrCancel, activityUpdate, activityFileDelete, zbhsignBookList, activityFileShareOrCancel, activityDeleteWritePage, activityFileTop, activityFileTheme, activityFileCancelTheme } from "@/api/adminservice/signIn"
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";
import { classList, zhktQueryDict, schoolList, schoolDelete, teacherList, teacherDelete } from "@/api/adminservice/classOrganizaMain";

export default {
    name: "groupSet",
    components: {
        perviewSiginCanvas
    },
    data() {
        return {
            visible:false,
            isSxShow: true,
            timestampToTime,
            showFileType: '图片素材',
            fileShow: false,
            isShare: false,
            shareUrl: '',
            selectCzObj: {},
            selectObj: {},
            schoolList: [],
            filesList: [],
            showUrlList: [],
            token: this.$storage.get("token"),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            showFileList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customId: '',
                status: '',
                endTime: [],
                releaseTime: [],
                pageNum: 1,
                pageSize: 10,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            orgList: [],
            customList: [],
            schoolId: '',
            scShow: false,
            subjectList: [],
            classList: [],
            role: '',
            isPerview: false,
            isTimeFalse: true,
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict();
        this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
    },
    mounted() {
        this.getList();
        this.getBookList();
    },
    methods: {
        canvasClPerview(e) {
            this.isPerview = false;
        },
        handlePerview(row) {
            this.selectCzObj = row;
            activityInfo({ activityId: this.selectCzObj.activityId }).then(res => {
                this.selectCzObj = res.data.data;
                console.log(this.selectCzObj);
                this.isPerview = true;
            })
        },
        handleShare(row) {
            this.shareUrl = '';
            this.isShare = true;
            this.shareUrl = 'www.baidu.com';
            setTimeout(() => {
                var iframe = document.getElementById("ckpxpfx"); // 获取到要关闭的iframe元素
                iframe.setAttribute("src", 'https://zbh.zbform.com/sign?activityId=' + row.activityId);
            })
            // this.shareUrl = 'https://zbsign.zbform.com/share?activityId='+row.activityId;
        },
        gbIfram() {
            var iframe = document.getElementById("ckpxpfx"); // 获取到要关闭的iframe元素
            iframe.setAttribute("src", '');
            console.log(iframe);
            if (typeof iframe !== "undefined" && iframe != null) {
                var contentWindow = iframe.contentWindow; // 获取到iframe内部的window对象
                if (typeof contentWindow !== "undefined" && contentWindow != null) {
                    console.log(1122);
                    contentWindow.close(); // 调用close()方法关闭iframe
                } else {
                    console.log("无效的iframe或者未加载完成！");
                }
            } else {
                console.log("没有找到指定的iframe元素！");
            }
        },
        handleFx() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            // if (this.selectObj.processStatus == 1 || this.selectObj.processStatus == 2) {
            //     this.$message.error("发布和停用的数据不能铺设");
            //     return;
            // }
            activityShareOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleStop() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.status != 1 && this.selectObj.status != 2) {
                this.$message.error("请选择发布或者结束的数据");
                return;
            }
            activityStopOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleRelease() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            // if (this.selectObj.processStatus == 1 || this.selectObj.processStatus == 2) {
            //     this.$message.error("发布和停用的数据不能铺设");
            //     return;
            // }
            activityReleaseOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        handleSCChange(e, type) {
            console.log(e, type)
            console.log(this.filesList);
            if (!(e.raw.type == 'image/jpeg' || e.raw.type == 'image/png' || e.raw.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (e.raw.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            let formdata = new FormData();
            formdata.append('files', e.raw)
            formdata.append('activityId', this.selectCzObj.activityId)
            activityFileUpload(formdata).then(res => {
                if (res.data.code == 1) {
                    this.getActivtyInfo()
                    if (this.isTimeFalse) {
                        this.$message.success("新增成功");
                    }
                    this.isTimeFalse = false;
                    setTimeout(() => {
                        this.isTimeFalse = true;
                    }, 5000)

                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        zdTop(fileId, index) {
            activityFileTop({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    this.getActivtyInfo()
                    // this.showFileList.splice(index, 1);
                    // this.showUrlList.splice(index, 1);
                    this.$message.success('操作成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        zdZhu(item, index) {
            if (item.isTheme == 0) {
                activityFileTheme({ fileId: item.fileId }).then(res => {
                    if (res.data.code == 1) {
                        this.getActivtyInfo()
                        // this.showFileList.splice(index, 1);
                        // this.showUrlList.splice(index, 1);
                        this.$message.success('操作成功');
                    } else {
                        this.$message.error('操作失败');
                    }
                })
            } else {
                activityFileCancelTheme({ fileId: item.fileId }).then(res => {
                    if (res.data.code == 1) {
                        this.getActivtyInfo()
                        // this.showFileList.splice(index, 1);
                        // this.showUrlList.splice(index, 1);
                        this.$message.success('操作成功');
                    } else {
                        this.$message.error('操作失败');
                    }
                })
            }

        },
        sharePicId(fileId, index) {
            activityFileShareOrCancel({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    if (this.showFileList[index].isShare == 1) {
                        this.showFileList[index].isShare = 0
                    } else {
                        this.showFileList[index].isShare = 1
                    }
                    // this.showFileList.splice(index, 1);
                    // this.showUrlList.splice(index, 1);
                    this.$message.success('操作成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        delPicId(fileId, index) {
            activityFileDelete({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    this.showFileList.splice(index, 1);
                    this.showUrlList.splice(index, 1);
                    this.$message.success('删除成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        handlePic(row) {
            this.selectCzObj = row;
            this.fileShow = true;
            this.getActivtyInfo()
            // showUrlList
        },
        handleDp(row) {
            // this.$router.push({
            //     path: '/signIn',// 这里的路径就可以正常的写，不需要添加_blank: true
            //     query:{
            //         activityId:row.activityId
            //     }
            // })
            let routeUrl = this.$router.resolve({
                path: '/signIn',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: {
                    activityId: row.activityId
                }
            })
            window.open(routeUrl.href, '_blank')
        },
        getActivtyInfo() {
            this.showUrlList = [];

            activityInfo({ activityId: this.selectCzObj.activityId }).then(res => {
                this.showFileList = res.data.data.fileList;
                this.showFileList.forEach(e => {
                    this.showUrlList.push(e.fileUrl)
                })

            })
        },
        changeCustomId() {
            this.queryParams.classId = '';
            this.queryParams.schoolId = '';
            schoolList({ customId: this.queryParams.customId ? this.queryParams.customId : this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
                this.getList();
            });
        },
        changeSchool() {
            this.queryParams.classId = '';
            classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
                this.classList = res.data.data;
                this.getList();
            });
        },
        getZhktQueryDict() {
            schoolList({ customId: this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
            });
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('060');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息技术有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
            });
            classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
                this.classList = res.data.data;
            });
        },
        excelAdd() {
            schoolList({ customId: this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
            });
            this.schoolId = ''
            this.scShow = true;
        },
        goDetail(row) {
            this.$router.push({
                path: "/eventMainDetail",
                query: {
                    activityId: row.activityId,
                }
            });
        },
        downloadTemplate() {
            download('https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E6%99%BA%E6%85%A7%E8%AF%BE%E5%A0%82%E8%80%81%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '导入教师模板')
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.scShow = false;
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds

        },
        changeCustom() {

        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (this.queryParams.endTime && this.queryParams.endTime.length != 0) {
                this.queryParams.beginEndTime = this.queryParams.endTime[0]
                this.queryParams.endEndTime = this.queryParams.endTime[1]
            } else {
                this.queryParams.beginEndTime = ''
                this.queryParams.endEndTime = ''
            }
            if (this.queryParams.releaseTime && this.queryParams.releaseTime.length != 0) {
                this.queryParams.beginReleaseTime = this.queryParams.releaseTime[0]
                this.queryParams.endReleaseTime = this.queryParams.releaseTime[1]
            } else {
                this.queryParams.beginReleaseTime = ''
                this.queryParams.endReleaseTime = ''
            }
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            this.loading = false;
            activityList(this.queryParams).then(res => {
                res.data.data.list.forEach(e => {
                    var memberListTxt = [];
                    e.memberList.forEach(j => {
                        memberListTxt.push(j.memberName)
                    })
                    e.memberListTxt = memberListTxt.join('、')
                })
                this.dataList = res.data.data.list;
                this.total = res.data.data.total;

            })
            // queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
            //     this.customList = res.data.data;
            //     if (!this.$route.query.customId) {
            //         this.queryParams.customId = res.data.data[0].customId;
            //         this.changeCustom()
            //     } else {
            //     }
            // });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            activityDelete({ activityId: row.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            if (this.$storage.get('role').includes(1)) {
                var st = Number(this.$storage.get('060StTime'))
                var end = Number(this.$storage.get('060EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.$router.push({
                path: "/eventMainAdd",
                query: {
                }
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/eventMainAdd",
                query: {
                    activityId: row.activityId,
                }
            });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.csbbb .eicon {
    /* display: none; */
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}

.csbbb .share {
    /* display: none; */
    position: absolute;
    right: 50px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb .Top {
    position: absolute;
    right: 80px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb .zhuti {
    position: absolute;
    right: 110px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .share {
    display: block;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #21ac74;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}</style>