<template>
    <div class="app-container" @click="visible = false;">
        <el-card shadow="never" v-loading="isSBBolean && { text: loadTxt }" :element-loading-text="loadTxt">
            <el-row>
                <el-col :span="24">
                    <div v-if="dataList.length != 0" class="fs-14" style="transform: translateY(-9px);text-align: center;">
                        表单名称：{{ formActive.formName }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 采集时间：{{
                            timestampToTime(formActive.createTime)
                        }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 采集人：{{
    formActive.member.memberName ? formActive.member.memberName :
    formActive.member.memberAccount }}
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-row>
                        <div style="display: flex;width: 100%;" class="">
                            <el-button class="" type="primary" @click="getqueryResult">表单识别</el-button>
                            <el-button style="margin-left: 20px" type="primary" @click="sumbit">确认提交</el-button>
                            <el-popover :visible="visible" popper-style="width: 420px !important;" placement="bottom"
                                :min-width="'auto'">
                                <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                                    @submit.native.prevent="getList">
                                    <el-row>
                                        <el-col :span="24" class="mb0 mr0 mt15">
                                            <el-form-item label="客户" v-if="role.includes(0)" class="w-100">
                                                <el-select style="width: 100%;" filterable @change="changeCustom"
                                                    v-model="queryParams.customId" placeholder="请选择">
                                                    <el-option v-for="item in customList" :key="item.customId"
                                                        :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                        :value="item.customId" />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24" class="mb0 mr0 mt15">
                                            <el-form-item label="场景" class="w-100">
                                                <el-select style="width: 100%;" @change="changeScene"
                                                    v-model="queryParams.sceneId" placeholder="请选择">
                                                    <el-option v-for="item in sceneList" :key="item.sceneId"
                                                        :label="item.customAbbreviation + ' | ' + item.sceneName"
                                                        :value="item.sceneId" />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24" class="mb0 mt15 mr0">
                                            <el-form-item label="表单" class="w-100">
                                                <el-select style="width: 100%;" clearable @change="changeList"
                                                    v-model="queryParams.formId" placeholder="请选择">
                                                    <el-option v-for="item in formList" :key="item.formId"
                                                        :label="item.formName" :value="item.formId" />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24" class="mb0 mt15 mr0">
                                            <el-form-item label="采集人员" class="w-100">
                                                <el-select filterable multiple clearable class="w-100" @change="changeList"
                                                    v-model="queryParams.userIdList" placeholder="请选择">
                                                    <el-option v-for="item in peopleList"
                                                        :label="item.memberName + '  ' + item.memberAccount"
                                                        :value="item.memberUserId" />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24" class="mb0 mt15 mr0">
                                            <el-form-item label="采集时间" class="w-100">
                                                <el-date-picker class="w-100" value-format="x" @change="changeList"
                                                    v-model="queryParams.createTime" type="datetimerange"
                                                    range-separator="至"
                                                    :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]"
                                                    start-placeholder="开始时间" end-placeholder="结束时间" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24" class="mb0 mt15 mr0">
                                            <el-form-item label="记录状态" class="w-100">
                                                <el-select filterable multiple clearable class="w-100" @change="changeList"
                                                    v-model="queryParams.finishtxtStutasList" placeholder="请选择">
                                                    <el-option label="填报" :value="0" />
                                                    <el-option label="已识别" :value="1" />
                                                    <el-option label="已确认" :value="2" />
                                                    <el-option label="已删除" :value="3" />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row class="mt15">
                                        <div style="margin:0 auto;">
                                            <el-button @click="getList()" type="primary">查找</el-button>
                                        </div>
                                    </el-row>
                                </el-form>
                                <template #reference>
                                    <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                                </template>
                            </el-popover>
                        </div>
                    </el-row>
                </el-col>
                <el-col :span="18">
                    <el-row>
                        <el-col :span="24">
                            <div class="chosebtn pull-right">
                                <div class="" v-if="active == 1"
                                    style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                                    <el-checkbox v-model="isSHowBj" />
                                    <div class="ml5" style="white-space: nowrap;">显示笔迹</div>
                                </div>
                                <div class="" v-if="active == 2"
                                    style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                                    <el-checkbox @change="changeShowNote" v-model="isSHowNote" />
                                    <div class="ml5" style="white-space: nowrap;">仅显示有笔记的表单项</div>
                                </div>
                                <!-- 隐藏分页 -->
                                <!-- <div style="margin-bottom: 5px;">
                                    <el-pagination small @current-change="changePages" v-model:current-page="currentNum"
                                        background layout="prev, pager, next" :pageCount="formActive.pageCount" />
                                </div> -->
                                <div>
                                    <img @click="changeActive(1)" :class="active == 1 ? 'bor-lan' : ''"
                                        src="@/assets/img/tr-card.png" alt="">
                                </div>
                                <div>
                                    <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''"
                                        src="@/assets/img/tr-table.png" alt="">
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                </el-col>
            </el-row>

            <el-row>
                <el-col :span="6">
                    <!-- <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="40px"
                        style="padding: 10px 10px;box-shadow:0 0 0 1px #dcdfe6" @submit.native.prevent="handleQuery">
                        <el-row class="" style="justify-content: space-between;">
                            <div>筛选条件</div>
                            <div>
                                <el-icon class="cursor" @click="isFilter = !isFilter" type="primary" :size="20">
                                    <ArrowDownBold v-if="!isFilter" />
                                    <ArrowUpBold v-if="isFilter" />
                                </el-icon>
                            </div>
                        </el-row>
                        <el-col :span="24" class="mb0 mr0 mt15" v-if="isFilter">
                            <el-form-item label="客户" v-if="role.includes(0)">
                                <el-select style="width: 100%;" filterable @change="changeCustom"
                                    v-model="queryParams.customId" placeholder="请选择">
                                    <el-option v-for="item in customList" :key="item.customId"
                                        :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                        :value="item.customId" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" class="mb0 mr0 mt15" v-if="isFilter">
                            <el-form-item label="场景">
                                <el-select style="width: 100%;" @change="changeScene" v-model="queryParams.sceneId"
                                    placeholder="请选择">
                                    <el-option v-for="item in sceneList" :key="item.sceneId"
                                        :label="item.customAbbreviation + ' | ' + item.sceneName" :value="item.sceneId" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" class="mb0 mt15 mr0" v-if="isFilter">
                            <el-form-item label="表单">
                                <el-select style="width: 100%;" clearable @change="changeList" v-model="queryParams.formId"
                                    placeholder="请选择">
                                    <el-option v-for="item in formList" :key="item.formId" :label="item.formName"
                                        :value="item.formId" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-form> -->
                    <el-row class="" style="padding: 10px 5px;box-shadow:0 0 0 1px #dcdfe6">
                        <el-col :span="16" class="mb15 sfdff">
                            <el-checkbox @change="changeBolean" style="margin-right: 2px;" v-model="boleanAll" />
                            表单记录
                        </el-col>
                        <el-col :span="8" style="display: flex;align-items: center;justify-content: end;">
                            <el-icon @click="getList()" class="mr10 cursor">
                                <Refresh />
                            </el-icon>
                            <el-popover placement="bottom-start" :width="125" trigger="hover" :min-width="'auto'">
                                <div class="pb10" style="text-align: center;">显示方式</div>
                                <div class="pxbutton" @click="changeCjradio('表单名称')"
                                    :style="cjradioList.includes('表单名称') ? 'background: #21ac74;color:#fff;' : ''">
                                    表单名称
                                </div>
                                <div class="pxbutton" @click="changeCjradio('记录标题')"
                                    :style="cjradioList.includes('记录标题') ? 'background: #21ac74;color:#fff;' : ''">
                                    记录标题
                                </div>
                                <div class="pxbutton" @click="changeCjradio('采集时间')"
                                    :style="cjradioList.includes('采集时间') ? 'background: #21ac74;color:#fff;' : ''">
                                    采集时间
                                </div>
                                <div class="pxbutton" @click="changeCjradio('采集人员')"
                                    :style="cjradioList.includes('采集人员') ? 'background: #21ac74;color:#fff;' : ''">
                                    采集人员
                                </div>
                                <div class="pxbutton" @click="changeCjradio('状态')"
                                    :style="cjradioList.includes('状态') ? 'background: #21ac74;color:#fff;' : ''">
                                    状态
                                </div>
                                <template #reference>
                                    <el-icon>
                                        <View />
                                    </el-icon>
                                </template>
                            </el-popover>

                        </el-col>
                        <el-col>
                            <div
                                :style="`${isFilter ? 'height:calc(100vh - 310px);' : 'height:calc(100vh - 370px);'}overflow-y: auto;`">
                                <div v-for="(item, index) in dataList"
                                    :style="formActive.recordId == item.recordId ? 'display:flex;background-color: #21ac741a;color:#21ac74' : 'display:flex;'"
                                    class="pb10 cursor cusdix sfdff">
                                    <el-checkbox class="" style="margin-right: 5px;" v-model="boleanObj[item.recordId]" />
                                    <div @click="changeRecord(item, index)"
                                        :style="`${cjradioList.length == 1 ? 'width:auto' : ''}`" :title="item.formName"
                                        style="" class="fdsdf text-one pt10 fs-14" v-if="cjradioList.includes('表单名称')">{{
                                            item.formName }}</div>
                                    <div @click="changeRecord(item, index)"
                                        :style="`${cjradioList.length == 1 ? 'width:auto' : ''}`" :title="item.recordName"
                                        style="" class="fdsdf text-one pt10 fs-14" v-if="cjradioList.includes('记录标题')">{{
                                            item.recordName }}</div>
                                    <div @click="changeRecord(item, index)" :title="timestampToTime(item.createTime)"
                                        style="padding-right:10px" class="text-one pt10 fs-14"
                                        v-if="cjradioList.includes('采集时间')">{{
                                            timestampToTime(item.createTime) }} </div>
                                    <div @click="changeRecord(item, index)" style="padding-right:10px"
                                        class="text-one pt10 fs-14" v-if="cjradioList.includes('采集人员') && item.member">{{
                                            item.member.memberName ? item.member.memberName : item.member.memberAccount }}</div>
                                    <div @click="changeRecord(item, index)" style="flex:1;text-align: right;width: 60px;"
                                        class="pt10 fs-14 text-one" v-if="cjradioList.includes('状态')">
                                        <span v-if="item.finishName == '已删除'">
                                            已删除
                                        </span>
                                        <span v-if="item.finishName == '填报'">
                                            填报
                                        </span>
                                        <span v-if="item.finishName == '已识别'">
                                            已识别
                                        </span>
                                        <span v-if="item.finishName == '已确认'">
                                            已确认
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style="height: 45px;">
                                <pagination :pagerCount="3" :background="false" :layout="'sizes, prev, pager, next'"
                                    v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                                    :limit.sync="queryParams.pageSize" @pagination="getList" />
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="18">
                    <div class="ml15">
                        <el-row v-show="active == 1" :v-loading="loading[formActive.recordId]" style="min-height: 30vh;">
                            <el-col :span="24">
                                <div style="height: calc(100vh - 210px); overflow-y: auto;background: rgb(243, 243, 243);margin-left: 15px;"
                                    v-if="active == 1 && dataList.length != 0 && !loading[formActive.recordId]">
                                    <div v-for="(items, index) in formActive.pageCount" style="margin: 10px;">

                                        <div id="" class=""
                                            :style="'margin:0 auto;width: ' + (lfimgwidth * 2) + 'px;height: ' + formActive.pageHeight / formActive.pageWidth * (lfimgwidth * 2) + 'px;position: relative;'">
                                            <img v-show="!isSHowBj"
                                                :style="'pointer-events: none;position:absolute;border:1px solid #eeeeee;border-radius:10px;width:' + (lfimgwidth * 2) + 'px;left:0;height:' + formActive.pageHeight / formActive.pageWidth * (lfimgwidth * 2) + 'px'"
                                                :src="formActive.formUrl + '/' + items + '.png'" alt="">
                                            <Canvasbox v-show="isSHowBj" v-if="isCanvas" :bookData="formActive"
                                                :imgList="[]" :imwidth="(lfimgwidth * 2)"
                                                :imheight="formActive.pageHeight / formActive.pageWidth * (lfimgwidth * 2)"
                                                :canvasid="formActive.formId + '3322' + index"
                                                :formDesignData="formDesignList[index]"
                                                :picSrc="formActive.formUrl + '/' + (index + 1) + '.png'"
                                                :detail="strokeList[index]">
                                            </Canvasbox>
                                            <div v-for="item in resultList" v-show="item.page == index + 1"
                                                :style="`position:absolute;left:${(item.isTable == 1 ? item.x1 : item.x) / formActive.pageWidth * (lfimgwidth * 2) + 1}px;top:${(item.isTable == 1 ? item.y1 : item.y) / formActive.pageWidth * (lfimgwidth * 2)}px;width:${(item.isTable == 1 ? item.w1 : item.width) / formActive.pageWidth * (lfimgwidth * 2)}px;height:${(item.isTable == 1 ? item.h1 : item.height) / formActive.pageWidth * (lfimgwidth * 2)}px;background:transparent;`">
                                                <el-input v-if="item.identifyType && item.identifyType != '勾选'"
                                                    v-model="item.identifyValue" spellcheck="false" type="textarea"
                                                    class="inputStyle"
                                                    style="z-index: 999;color: rgb(252, 3, 3);font-weight: bold;font-size: 20px;"
                                                    placeholder=" " />
                                            </div>
                                            <div :class="item.identifyValue == 'Y' ? 'inputStyle zsj' : 'inputStyle tranaa'"
                                                v-for="item in resultList" v-show="item.page == index + 1"
                                                :style="`position:absolute;left:${(item.isTable == 1 ? item.x1 : item.x) / formActive.pageWidth * (lfimgwidth * 2) + 1}px;top:${(item.isTable == 1 ? item.y1 : item.y) / formActive.pageWidth * (lfimgwidth * 2)}px;width:${(item.isTable == 1 ? item.w1 : item.width) / formActive.pageWidth * (lfimgwidth * 2)}px;height:${(item.isTable == 1 ? item.h1 : item.height) / formActive.pageWidth * (lfimgwidth * 2)}px;background:transparent;`">
                                                <el-select class="tran" @change="selectChange($event, item)"
                                                    v-if="item.identifyType && item.identifyType == '勾选'"
                                                    style="background:transparent;font-size: 20px;"
                                                    v-model="item.identifyValue" placeholder=" ">
                                                    <el-option key="yes" label="Y" value="Y" />
                                                    <el-option key="no" label="N" value="N" />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row v-show="active == 2">
                            <el-col :span="12" id="lfimgwidth"
                                style="height: calc(100vh - 210px);overflow-y: auto;box-shadow:0 0 0 1px #dcdfe6;background: rgb(243, 243, 243);">
                                <div v-if="dataList.length != 0" v-for="(item, index) in formActive.pageCount"
                                    class="cursor" @click="openIsLfPerview(index)" style="margin: 10px;">
                                    <!-- 隐藏分页 -->
                                    <!-- <Canvasbox v-show="currentNum == index + 1" v-if="isCanvas" :bookData="formActive" -->
                                    <Canvasbox v-if="isCanvas" :bookData="formActive" :imgList="[]" :imwidth="lfimgwidth"
                                        :imheight="formActive.pageHeight / formActive.pageWidth * lfimgwidth"
                                        :canvasid="formActive.formId + '22' + index" :formDesignData="formDesignList[index]"
                                        :picSrc="formActive.formUrl + '/' + item + '.png'" :detail="strokeList[index]">
                                    </Canvasbox>
                                </div>
                            </el-col>
                            <el-col :span="12" :v-loading="loading[formActive.recordId]">
                                <!-- 隐藏分页 -->
                                <!-- <el-table v-if="active == 2 && !loading"
                                    :row-class-name="isSHowNote ? tableRowClassName : ''" v-loading="loading" class="ml15"
                                    :data="showTableTesult" style="width: 100%" height="calc(100vh - 170px)" border> -->
                                <el-table v-if="active == 2 && dataList.length != 0"
                                    :row-class-name="isSHowNote ? tableRowClassName : ''"
                                    v-loading="loading[formActive.recordId]" class="ml15" :data="tableResultList"
                                    style="width: 100%" height="calc(100vh - 210px)" border>
                                    <el-table-column align="center" prop="identifyName" label="表单项" width="150">
                                        <template #default="scope">
                                            <div v-if="scope.row.isTable != 1">
                                                {{ scope.row.identifyName }}
                                                {{ scope.row.isModify == 1 ? '●' : ' ○' }}
                                            </div>
                                            <div v-if="scope.row.isTable == 1">
                                                <div v-if="scope.row.tableType == '普通'">
                                                    {{ scope.row.identifyName }}
                                                    <!-- {{ scope.row.isModify == 1 ? '●' : ' ○' }} -->
                                                </div>
                                                <div v-if="scope.row.tableType != '普通'">
                                                    {{ scope.row.identifyName }}
                                                    <!-- {{ scope.row.isModify == 1 ? '●' : ' ○' }} -->
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="identifyValue" label="识别结果">
                                        <template #default="scope">
                                            <div v-if="scope.row.isTable == 0">
                                                <el-input v-if="scope.row.identifyType != '勾选'"
                                                    v-model="scope.row.identifyValue" placeholder=" " />
                                                <el-select v-if="scope.row.identifyType == '勾选'"
                                                    v-model="scope.row.identifyValue" class="m-2" placeholder=" ">
                                                    <el-option key="yes" label="Y" value="Y" />
                                                    <el-option key="no" label="N" value="N" />
                                                </el-select>
                                            </div>
                                            <div v-else style="text-align: center;">
                                                <el-link :title="scope.row.reuTxt ? scope.row.reuTxt : '表格'"
                                                    @click="editTable(scope.row)" :underline="false" class="text-one"
                                                    type="primary">
                                                    <div class="text-one" style="text-align: left;">{{ scope.row.reuTxt ?
                                                        scope.row.reuTxt : '表格' }}
                                                    </div>
                                                </el-link>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="strokeList" label="原始笔迹" width="180">
                                        <template #default="scope">
                                            <div class="cursor" @click="openIsPerview(scope.row, scope.row)">
                                                <Canvasbox v-if="isTwoCanvas && !loading[formActive.recordId]"
                                                    :bookData="scope.row" :imgList="[]" :imwidth="150"
                                                    :imheight="scope.row.pageHeight / scope.row.pageWidth * 150"
                                                    :canvasid="formActive.recordId + scope.row.designId + Number(new Date())"
                                                    :picSrc="scope.row.img" :xS="scope.row.x" :yS="scope.row.y"
                                                    :detail="scope.row.strokeList">
                                                </Canvasbox>
                                            </div>
                                            <!-- <div v-if="scope.row.isFinish == 0">填报</div> -->
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                        <!-- <el-row>
                            <div class="mt10 mb10 fs-14">
                                表单名称：{{ formActive.formName }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 采集时间：{{
                                    timestampToTime(formActive.createTime)
                                }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 采集人：{{ formActive.memberName }}
                            </div>
                        </el-row> -->
                        <!-- <el-row>
                            <div style="margin: 0 auto;">
                                <el-pagination @current-change="changePages" v-model:current-page="currentNum" background
                                    layout="prev, pager, next" :pageCount="formActive.pageCount" />
                            </div>
                        </el-row> -->
                        <!-- <el-row>
                            <div style="margin: 0 auto;" class="mt20">
                                <el-button class="mr10" type="primary" @click="getqueryResult">表单识别</el-button>
                                <el-button type="primary" @click="sumbit">确认提交</el-button>
                            </div>
                        </el-row> -->
                    </div>
                </el-col>
            </el-row>
        </el-card>

        <PerviewCanvas :currentNum="1" :strokeList="exStrList" :bookData="activeBookData" :xS="activeBookData.x"
            :yS="activeBookData.y" @canvasClose="canvasClPerview" v-if="isPerview">
        </PerviewCanvas>
        <PerviewCanvas :currentNum="currentNum" :strokeList="exLfStrList" :bookData="formActive"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList))" @canvasClose="canvasClLfPerview"
            v-if="isLfPerview">
        </PerviewCanvas>
        <el-dialog :width="lfimgwidth * 2 + 60" draggable v-if="isEditTable" v-model="isEditTable" align-center
            style="border-radius: 20px" title="编辑表格">
            <div style="height: calc(100vh - 320px); overflow-y: auto;">
                <div style="">
                    <Canvasbox v-if="isEditTable" :bookData="tableActive" :imgList="[]" :xS="tableActive.x"
                        :yS="tableActive.y" :imwidth="(lfimgwidth * 2)"
                        :imheight="tableActive.pageHeight / tableActive.pageWidth * (lfimgwidth * 2)"
                        :canvasid="formActive.formId + '22111111'" :picSrc="tableActive.img"
                        :detail="tableActive.strokeList">
                    </Canvasbox>
                </div>
                <div style="margin-top: 30px;">
                    <div id="" class=""
                        :style="'margin:0 auto;width: ' + (lfimgwidth * 2) + 'px;height: ' + tableActive.pageHeight / tableActive.pageWidth * (lfimgwidth * 2) + 'px;position: relative;'">
                        <img :style="'pointer-events: none;position:absolute;border:1px solid #eeeeee;border-radius:10px;width:' + (lfimgwidth * 2) + 'px;left:0;height:' + tableActive.pageHeight / tableActive.pageWidth * (lfimgwidth * 2) + 'px'"
                            :src="tableActive.img" alt="">
                        <div v-for="item in tableActive.tableList"
                            :style="`position:absolute;left:${(item.isTable == 1 ? (item.x1 - tableActive.x) : item.x) / tableActive.pageWidth * (lfimgwidth * 2) + 1}px;top:${(item.isTable == 1 ? (item.y1 - tableActive.y) : item.y) / tableActive.pageWidth * (lfimgwidth * 2)}px;width:${(item.isTable == 1 ? item.w1 : item.width) / tableActive.pageWidth * (lfimgwidth * 2)}px;height:${(item.isTable == 1 ? item.h1 : item.height) / tableActive.pageWidth * (lfimgwidth * 2)}px;background:transparent;`">
                            <el-input v-if="item.identifyType && item.identifyType != '勾选'" v-model="item.identifyValue"
                                spellcheck="false" type="textarea" class="inputStyle"
                                style="z-index: 1007;left: 0;color: rgb(252, 3, 3);font-weight: bold;" placeholder=" " />
                        </div>
                        <div :class="item.identifyValue == 'Y' ? 'inputStyle zsj' : 'inputStyle tranaa'"
                            v-for="item in tableActive.tableList"
                            :style="`position:absolute;left:${(item.isTable == 1 ? (item.x1 - tableActive.x) : item.x) / tableActive.pageWidth * (lfimgwidth * 2) + 1}px;top:${(item.isTable == 1 ? (item.y1 - tableActive.y) : item.y) / tableActive.pageWidth * (lfimgwidth * 2)}px;width:${(item.isTable == 1 ? item.w1 : item.width) / tableActive.pageWidth * (lfimgwidth * 2)}px;height:${(item.isTable == 1 ? item.h1 : item.height) / tableActive.pageWidth * (lfimgwidth * 2)}px;background:transparent;`">
                            <el-select class="tran" @change="selectChange($event, item)"
                                v-if="item.identifyType && item.identifyType == '勾选'" style="background:transparent;"
                                v-model="item.identifyValue" placeholder=" ">
                                <el-option key="yes" label="Y" value="Y" />
                                <el-option key="no" label="N" value="N" />
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { queryCustoms } from "@/api/adminservice/custom";

import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { formQuery, deleteForm, formRelease, formStop, formRecordDesign } from "@/api/adminservice/form"
import { download } from "@/api/adminservice/public"
import { queryScene, sceneInfo } from "@/api/adminservice/scene"
import { queryAllRecords, queryResult, strokeQuery, tableInfo, saveResult, queryLastIdentify } from "@/api/adminservice/formRecord"

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            visible: false,
            loadTxt: '识别进度',
            isSHowNote: true,
            isSHowBj: true,
            isSBBolean: false,
            exStrList: [],
            exLfStrList: [],
            activeBookData: {},
            cjradioList: ['采集时间', '采集人员', '状态'],
            isFilter: false,
            currentNum: 1,
            active: 2,
            tableData: [],
            formActive: {
                pageCount: 0,
                member: {}
            },
            customList: [],
            strokeList: [],
            lfimgwidth: 400,
            statusList: [
                { name: '未铺设', value: 0 },
                { name: '铺设中', value: 1 },
                { name: '铺设成功', value: 2 },
                { name: '铺设失败', value: 3 },
                { name: '发布', value: 4 },
                { name: '停用', value: 5 },
            ],
            sceneList: [],
            formList: [],
            timestampToTime,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: {},
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            peopleList: [],
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            boleanObj: {},
            boleanAll: false,
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                customId: '',
                screenName: '',
                status: '',
                formId: '',
                sceneId: '',
                isDesign: '',
                customId: '',
                createTime: [],
                releaseTime: [],
                memberName: '',
                beginTime: '',
                endTime: '',
                isDelete: '',
                userIdList: [],
                finishtxtStutasList: []
            },
            showTableTesult: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            result: [],
            isCanvas: false,
            isTwoCanvas: false,
            resultList: [],
            oldResultList: [],
            csobj: {},
            tableResultList: [],
            isPerview: false,
            isLfPerview: false,
            isEditTable: false,
            tableActive: {},
            activeIndex: 0,
            role: '',
            formDesignData: [],
            formDesignList: [],
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getSceneList();
        this.getFormList();
        window.onkeydown = this.keyDown;
    },
    mounted() {
        this.lfimgwidth = (document.getElementById("lfimgwidth").clientWidth - 40)
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        changeList() {
            this.getList()
        },
        tableRowClassName: function (row, index) {
            // console.log(row.row)
            if (row.row.strokeList && row.row.strokeList.length === 0) {
                return 'hidden-row';
            }
            return '';
        },

        changeShowNote() {
            // console.log(this.isSHowNote);
        },
        keyDown(event) {
            // console.log(event.key)
            if (event.key === 'ArrowUp') {
                if (this.activeIndex - 1 >= 0) {
                    this.changeRecord(this.dataList[this.activeIndex - 1], this.activeIndex - 1)
                }
                // this.ctrl = true;
            } else if (event.key === 'ArrowDown') {
                if (this.activeIndex + 1 < this.dataList.length) {
                    this.changeRecord(this.dataList[this.activeIndex + 1], this.activeIndex + 1)
                }
            }
        },
        changeCustom() {
            this.peopleList = [];
            this.dataList = [];
            this.queryParams.sceneId = '';
            this.queryParams.formId = '';
            this.formList = [];

            queryScene({ customId: this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId') }).then(res => {
                if (this.role.includes(0)) {
                    if (this.queryParams.customId !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.customId == this.queryParams.customId
                        })
                    }
                }
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
                if (this.sceneList.length != 0) {
                    this.queryParams.sceneId = this.sceneList[0].sceneId;
                    sceneInfo({ sceneId: this.sceneList[0].sceneId }).then(res => {
                        this.peopleList = res.data.data.memberList;
                    })
                    this.getList();
                    this.getFormList();
                }
            });
        },
        changeScene() {
            this.peopleList = [];
            this.dataList = [];
            if (this.queryParams.sceneId !== '') {
                sceneInfo({ sceneId: this.queryParams.sceneId }).then(res => {
                    this.peopleList = res.data.data.memberList;
                })
            }
            this.queryParams.formId = '';
            this.getFormList();
            this.getList();
        },
        editTable(item) {
            this.tableActive = item;
            console.log(item)
            setTimeout(() => {
                this.isEditTable = true;
            })

        },
        openIsPerview(item) {
            this.activeBookData = item;
            this.exStrList = [{ pageNum: 1, strokeList: item.strokeList }]
            // console.log(item)
            this.isPerview = true;
        },
        openIsLfPerview(item) {
            this.currentNum = item + 1;
            this.isLfPerview = true;
        },
        canvasClPerview(e) {
            this.isPerview = false;
        },
        canvasClLfPerview(e) {
            this.isLfPerview = false;
        },
        changeCjradio(e) {
            if (this.cjradioList.indexOf(e) == -1) {
                this.cjradioList.push(e)
            } else {
                if (this.cjradioList.length == 1) {
                    return
                }
                this.cjradioList.splice(this.cjradioList.indexOf(e), 1)
            }
        },
        selectChange(val, item) {
            // console.log(val, item)
            // if(item.isTable == 1){
            //     if(item.tableType == '单选'){

            //     }
            // }
        },
        changeTableResult() {
            this.resultList = []
            this.tableResultList.forEach(e => {
                if (e.isTable == 0) {
                    this.resultList.push(e)
                } else {
                    // console.log(e.tableList)
                    e.tableList.forEach(j => {
                        this.resultList.push(j)
                    })
                }
            })
        },
        sumbit() {
            // console.log(this.formActive)
            // console.log(this.resultList)
            // console.log(this.tableResultList)
            if (this.formActive.isIdentify == 0) {
                this.$message.error('请选择识别状态的数据')
                return
            }
            if (this.active == 2) {
                this.changeTableResult()
            }
            this.resultList.forEach(e => {
                this.oldResultList.forEach(j => {
                    if (e.designId == j.designId && e.identifyValue != j.identifyValue) {
                        e.isModify = 1
                    }
                })
            })
            saveResult({
                identifyList: this.resultList,
                recordId: this.formActive.recordId
            }).then(res => {
                if (res.data.code == 1) {
                    this.formActive.finishName = '已确认'
                    this.$message.success("提交成功");
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        changeBolean() {
            console.log(this.boleanAll);
            if (this.boleanAll) {
                for (let as in this.boleanObj) {
                    this.boleanObj[as] = true;
                }
            } else {
                for (let as in this.boleanObj) {
                    this.boleanObj[as] = false;
                }
            }
        },
        getqueryResult() {
            for (let as in this.loading) {
                this.loading[as] = false;
            }
            setTimeout(() => {
                let arr1 = []
                var recList = []
                for (let sb in this.boleanObj) {
                    if (this.boleanObj[sb]) {
                        recList.push(sb)
                    }
                }
                if (recList.length == 0) {
                    this.$message.error('请选择记录');
                    return;
                }

                this.isSBBolean = true;
                let that = this;
                this.loadTxt = '识别进度0/' + recList.length
                // setTimeout(() => {
                //     this.loadTxt = '识别进度222/' + recList.length
                // }, 1000);
                var jdNum = 0
                recList.forEach(sb => {
                    arr1.push(new Promise(function (resolve, reject) {

                        queryResult({ recordId: sb }).then(res => {
                            if (res.data.code == 1) {
                                jdNum++;
                                that.loadTxt = '识别进度' + jdNum + '/' + recList.length
                                that.dataList.forEach(e => {
                                    if (e.recordId == sb) {
                                        e.finishName = '已识别';
                                    }
                                })
                                res.data.data.finishName = '已识别'
                                console.log(that.formActive.recordId, sb);
                                if (that.formActive.recordId == sb) {
                                    that.loading[that.formActive.recordId] = true;
                                    that.isTwoCanvas = false;
                                    that.formActive.finishName = '已识别'
                                    that.resultList = res.data.data;
                                    that.oldResultList = JSON.parse(JSON.stringify(res.data.data))
                                    let arr = []
                                    let obj = {}
                                    that.resultList.forEach((e, i) => {
                                        e.pageWidth = e.width
                                        e.pageHeight = e.height
                                        if (e.isTable == 1) {
                                            e.x1 = e.x;
                                            e.y1 = e.y;
                                            e.h1 = e.height;
                                            e.w1 = e.width;
                                            if (!obj.hasOwnProperty(e.num)) {

                                                arr.push(new Promise(function (resolve, reject) {
                                                    obj[e.num] = {};
                                                    tableInfo({ designId: e.designId, num: e.num, recordId: e.recordId }).then(ress => {
                                                        if (ress.data.data) {
                                                            obj[e.num] = ress.data.data
                                                            e.height = ress.data.data.height
                                                            e.width = ress.data.data.width
                                                            e.x = ress.data.data.x
                                                            e.y = ress.data.data.y
                                                            e.pageWidth = ress.data.data.width
                                                            e.pageHeight = ress.data.data.height
                                                        }

                                                        resolve(res.data.data)
                                                    })
                                                }))
                                            } else {
                                                e.height = obj[e.num].height
                                                e.width = obj[e.num].width
                                                e.x = obj[e.num].x
                                                e.y = obj[e.num].y
                                                e.pageWidth = obj[e.num].width
                                                e.pageHeight = obj[e.num].height
                                            }
                                        }
                                    })
                                    Promise.all(arr).then((result) => {
                                        that.boleanAll = false;
                                        for (let as in that.boleanObj) {
                                            that.boleanObj[as] = false;
                                        }
                                        that.tableData = that.resultList.filter(itemA => {
                                            return itemA.page.toString() == that.currentNum
                                        })
                                        that.isTwoCanvas = true;
                                        setTimeout(() => {
                                            that.loading[that.formActive.recordId] = false;
                                            that.getTableResult();
                                        })
                                    })
                                }

                            } else {
                            }
                            resolve(res.data)
                        })
                    }))
                })
                Promise.all(arr1).then((result) => {
                    this.isSBBolean = false;
                })

                // queryResult({ recordId: this.formActive.recordId }).then(res => {
                //     if (res.data.code == 1) {
                //         this.formActive.isIdentify = 1
                //     } else {
                //     }
                //     this.resultList = res.data.data;
                //     this.oldResultList = JSON.parse(JSON.stringify(res.data.data))
                //     let arr = []
                //     let obj = {}
                //     this.resultList.forEach((e, i) => {
                //         e.pageWidth = e.width
                //         e.pageHeight = e.height
                //         if (e.isTable == 1) {
                //             e.x1 = e.x;
                //             e.y1 = e.y;
                //             e.h1 = e.height;
                //             e.w1 = e.width;
                //             if (!obj.hasOwnProperty(e.num)) {

                //                 arr.push(new Promise(function (resolve, reject) {
                //                     obj[e.num] = {};
                //                     tableInfo({ designId: e.designId, num: e.num, recordId: e.recordId }).then(ress => {
                //                         if (ress.data.data) {
                //                             obj[e.num] = ress.data.data
                //                             e.height = ress.data.data.height
                //                             e.width = ress.data.data.width
                //                             e.x = ress.data.data.x
                //                             e.y = ress.data.data.y
                //                             e.pageWidth = ress.data.data.width
                //                             e.pageHeight = ress.data.data.height
                //                         }

                //                         resolve(res.data.data)
                //                     })
                //                 }))
                //             } else {
                //                 e.height = obj[e.num].height
                //                 e.width = obj[e.num].width
                //                 e.x = obj[e.num].x
                //                 e.y = obj[e.num].y
                //                 e.pageWidth = obj[e.num].width
                //                 e.pageHeight = obj[e.num].height
                //             }
                //         }
                //     })
                //     Promise.all(arr).then((result) => {
                //         for (let as in this.boleanObj) {
                //             this.boleanObj[as] = false;
                //         }
                //         this.tableData = this.resultList.filter(itemA => {
                //             return itemA.page.toString() == this.currentNum
                //         })
                //         this.isTwoCanvas = true;
                //         setTimeout(() => {
                //             this.loading[this.formActive.recordId] = false;
                //             this.getTableResult();
                //         })
                //     })
                // })
            })
        },
        getTableResult() {
            this.tableResultList = [];
            var tableResultArrAY = JSON.parse(JSON.stringify(this.resultList))
            var isArray = { num: '' }
            // console.log(tableResultArrAY)
            tableResultArrAY.forEach((e, i) => {
                if (e.recordId == this.formActive.recordId) {
                    if (e.isTable == 1) {
                        if (isArray.num != e.num) {
                            isArray = JSON.parse(JSON.stringify(e))
                            isArray.tableList = [e]
                            this.tableResultList.push(isArray)
                        } else {
                            isArray.strokeList = isArray.strokeList.concat(e.strokeList)
                            isArray.tableList.push(e)
                            this.tableResultList[this.tableResultList.length - 1] = isArray
                        }
                    } else {
                        this.tableResultList.push(e)
                        isArray = { num: '' }
                    }
                }
            })
            this.showTableTesult = this.tableResultList.filter(itemA => {
                return itemA.page.toString() == this.currentNum
            })
            this.tableResultList.forEach(e => {
                if (e.tableList) {
                    var reuList = []
                    var reuObj = {}
                    e.tableList.forEach(j => {
                        if (j.tableType == '单选' || j.tableType == '多选') {
                            if (j.identifyValue == 'Y') {
                                reuList.push(j.itemValue)
                            }
                        } else {
                            if (reuObj[j.rowNum]) {
                                reuObj[j.rowNum].push(j.identifyValue)
                            } else {
                                reuObj[j.rowNum] = [j.identifyValue]
                            }

                        }
                    })
                    if (e.tableType == '普通') {
                        for (let as in reuObj) {
                            reuList.push(reuObj[as].join(','))
                        }
                        e.reuTxt = reuList.join(';')
                    } else {
                        e.reuTxt = reuList.join(',')
                    }


                    console.log(reuObj);
                }
            })
            console.log(this.tableResultList)
        },
        changePages(val) {
            this.isTwoCanvas = false;
            // console.log(this.resultList)
            // console.log(val)
            this.currentNum = val;
            this.tableData = this.resultList.filter(itemA => {
                return itemA.page.toString() == this.currentNum
            })
            this.showTableTesult = this.tableResultList.filter(itemA => {
                return itemA.page.toString() == this.currentNum
            })
            setTimeout(() => {
                // this.getTableResult()
                this.isTwoCanvas = true;
            })
        },
        changeRecord(e, num) {
            this.active = 2;
            for (let as in this.loading) {
                this.loading[as] = false;
            }
            setTimeout(() => {
                this.activeIndex = num;
                // console.log(e)
                this.formDesignList = [];
                this.tableData = []
                this.strokeList = []
                this.exLfStrList = []
                this.isCanvas = false;
                this.currentNum = 1;
                this.formActive = e;
                this.loading[this.formActive.recordId] = true;
                let arr = []
                formRecordDesign({ recordId: this.formActive.recordId }).then(response => {
                    this.formDesignData = response.data.data;
                    queryLastIdentify({ recordId: this.formActive.recordId }).then(res => {
                        // console.log(res.data.data)
                        this.resultList = [...res.data.data];
                        this.oldResultList = JSON.parse(JSON.stringify(res.data.data))

                        let arr = []
                        let obj = {}
                        this.resultList.forEach((e, i) => {
                            e.pageWidth = e.width
                            e.pageHeight = e.height
                            // if (e.isTable == 1 && e.tableType != '普通'){
                            //     e.identifyValue = e.itemValue;
                            // }
                            if (e.isTable == 1) {
                                e.x1 = e.x;
                                e.y1 = e.y;
                                e.h1 = e.height;
                                e.w1 = e.width;
                                if (!obj.hasOwnProperty(e.num)) {

                                    arr.push(new Promise(function (resolve, reject) {
                                        // console.log(obj.hasOwnProperty(e.num))
                                        obj[e.num] = {};
                                        // if (!obj.hasOwnProperty(e.num)) {
                                        tableInfo({ designId: e.designId, num: e.num, recordId: e.recordId }).then(ress => {
                                            console.log(ress.data.data);
                                            if (ress.data.data) {
                                                obj[e.num] = ress.data.data
                                                // console.log(obj.hasOwnProperty(e.num))
                                                e.height = ress.data.data.height
                                                e.width = ress.data.data.width
                                                e.x = ress.data.data.x
                                                e.y = ress.data.data.y
                                                e.pageWidth = ress.data.data.width
                                                e.pageHeight = ress.data.data.height
                                            }

                                            resolve(res.data.data)
                                        })
                                        // }

                                    }))
                                } else {
                                    e.height = obj[e.num].height
                                    e.width = obj[e.num].width
                                    e.x = obj[e.num].x
                                    e.y = obj[e.num].y
                                    e.pageWidth = obj[e.num].width
                                    e.pageHeight = obj[e.num].height
                                }
                            }
                        })
                        Promise.all(arr).then((result) => {
                            // console.log(obj);
                            this.tableData = this.resultList.filter(itemA => {
                                return itemA.page.toString() == this.currentNum
                            })
                            this.getTableResult();
                            this.loading[this.formActive.recordId] = false;
                            this.isTwoCanvas = true;
                        })
                    })
                    for (var i = 0; i < this.formActive.pageCount; i++) {
                        this.formDesignList.push([])
                        var _this = this;
                        arr.push(new Promise(function (resolve, reject) {
                            strokeQuery({ pageNum: i + 1, recordId: _this.formActive.recordId }).then(res => {
                                resolve(res.data.data)
                            })
                        }))
                    }
                    Promise.all(arr).then((result) => {
                        // console.log(result)
                        this.strokeList = result;
                        this.isCanvas = true;
                        result.forEach((e, i) => {
                            this.exLfStrList.push({
                                pageNum: i + 1,
                                strokeList: e
                            })

                            this.formDesignData.forEach(m => {
                                if (m.page == (i + 1)) {
                                    this.formDesignList[i].push(m)
                                }
                            })
                        })
                    })
                }).catch(error => {

                })
            })
        },
        changeActive(num) {
            this.active = num
            // this.getTableResult()
            this.tableData = this.resultList.filter(itemA => {
                return itemA.page.toString() == this.currentNum
            })
            if (num == 1) {
                this.changeTableResult()
            }
            if (num == 2) {

            }
        },
        handleCurrentChange(val) {
            // console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            // console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        getSceneList() {
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                // this.customList = res.data.data;
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('010');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息技术有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
                this.queryParams.customId = this.customList[0].customId;
                queryScene({ customId: this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId') }).then(res => {
                    if (this.role.includes(0)) {
                        if (this.queryParams.customId !== '') {
                            res.data.data = res.data.data.filter(itemA => {
                                return itemA.customId == this.queryParams.customId
                            })
                        }
                    }
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneType != 1;
                    })
                    this.sceneList = res.data.data;
                    this.queryParams.sceneId = res.data.data[0].sceneId
                    if (this.queryParams.sceneId !== '') {
                        sceneInfo({ sceneId: this.queryParams.sceneId }).then(res => {
                            this.peopleList = res.data.data.memberList;
                        })
                    }
                    this.getList();
                });
            });

        },
        getFormList() {
            formQuery({ customId: this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId') }).then(res => {
                // res.data.data = res.data.data.filter(itemA => {
                //     return itemA.status == 4
                // })
                // if (this.queryParams.customId !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.customId == this.queryParams.customId
                //     })
                // }
                if (this.queryParams.sceneId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneId.toString().indexOf(this.queryParams.sceneId) != -1
                    })
                }
                console.log(this.formList);
                this.formList = res.data.data;
            });
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.queryParams.finishStutasList = []
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (this.queryParams.createTime && this.queryParams.createTime.length != 0) {
                this.queryParams.beginTime = this.queryParams.createTime[0]
                this.queryParams.endTime = this.queryParams.createTime[1]
            } else {
                this.queryParams.beginTime = ''
                this.queryParams.endTime = ''
            }
            this.dataList = [];
            this.boleanAll = false;
            this.boleanObj = {};
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            if (this.queryParams.finishtxtStutasList.includes(3)) {
                this.queryParams.finishtxtStutasList.forEach(e => {
                    if (e != 3) {
                        this.queryParams.finishStutasList.push(e)
                    }
                })
                this.queryParams.isDelete = 1
            } else {
                this.queryParams.finishStutasList = this.queryParams.finishtxtStutasList
                this.queryParams.isDelete = 0
            }
            if (this.queryParams.finishtxtStutasList.length == 0) {
                this.queryParams.isDelete = ''
            }
            queryAllRecords(this.queryParams).then(res => {
                this.dataList = res.data.data.list;
                this.dataList.forEach(e => {
                    if (e.isDelete == 1) {
                        e.finishName = '已删除'
                    } else {
                        if (e.isFinish == 0) {
                            e.finishName = '填报'
                        } else if (e.isFinish == 1) {
                            e.finishName = '已识别'
                        } else if (e.isFinish == 2) {
                            e.finishName = '已确认'
                        }
                    }
                    this.boleanObj[e.recordId] = false
                })
                this.total = res.data.data.total;
                if (this.dataList.length != 0) {
                    this.changeRecord(this.dataList[0], 0);
                }
            });
        },

        // 停用
        handleStop(row) {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            formStop({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        handleRelease() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            formRelease({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleXz() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            download(this.selectObj.formUrl + '/original.pdf', this.selectObj.formName)
        },
        hadlePs() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            let routeUrl = this.$router.resolve({
                path: '/ZBDesigner',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userId: JSON.parse(this.$storage.get('userInfo')).userId, formId: this.selectObj.formId }
            })
            window.open(routeUrl.href, '_blank')

            // var url = "http://192.168.101.116:8080/?userId=" + JSON.parse(this.$storage.get('userInfo')).userId + "&formId=" + this.selectObj.formId
            // window.open(url)
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            deleteForm({ formId: row.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/formRecordDetail",
                query: {
                    formId: row.formId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/formMainAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/formMainAdd",
                query: {
                    formId: row.formId,
                }
            });
        }
    }
};
</script>
<style scoped>
.el-table /deep/ .hidden-row {
    display: none;
}

.zsj /deep/ .el-input__inner {
    color: rgb(252, 3, 3);
    text-align: right;
    /* font-weight: bold; */
}

.tranaa /deep/ .el-input__inner {
    color: transparent;
    /* font-weight: bold; */
}

.el-input__wrapper {}

.mr0 /deep/ .el-form-item {
    margin-right: 0 !important;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.image {
    width: 100%;
    display: block;
}

.sfdff /deep/ .el-checkbox {
    transform: translateY(3px);
    height: auto !important;
}

.chosebtn {
    /* padding-top: 10px; */
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #21ac74
}

.wbba {
    word-break: break-all;
}

.cusdix {
    height: 28px;
    /* padding-left: 10px; */
    padding-right: 10px;
}

.inputStyle {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;

}

.inputStyle /deep/ .el-textarea__inner {
    height: 100% !important;
    color: unset !important;
    text-decoration: none !important;
    text-decoration-style: none !important;
    resize: none !important;
    outline: none !important;
    min-height: auto !important;
    padding: 0 2px !important;
}

.inputStyle /deep/ .el-select__popper.el-popper {
    padding: 0;
    z-index: 99999999 !important;
}

.inputStyle .el-input__prefix {
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding-left: 30px;
    left: 0;
    visibility: hidden;
}

.inputStyle .el-input__inner {
    position: absolute;
}

.inputStyle /deep/ .el-textarea__inner {
    background: transparent;
    padding: 5px;
}

.inputStyle /deep/ .el-select {
    height: 100%;
}

.inputStyle /deep/ .select-trigger {
    height: 100%;
}

.inputStyle /deep/ .el-select .el-input {
    height: 100%;
}

.tran /deep/ .el-input__wrapper {
    background: transparent;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.fdsdf {
    padding-right: 10px;
    width: 85px;
}

/deep/ .pagination-container {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
</style>