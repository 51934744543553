<template>
    <div class="app-container" @click="visible = false;">
        <el-card>
            <el-row>
                <el-col :span="24" class="mb0 mr0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px">
                                <el-row class="">
                                    <el-col :span="24" class=" mr0" v-if="role.includes(0)">
                                        <el-form-item label="所属客户" prop="termId" class="w-100">
                                            <el-select class="w-100" clearable @change="changeCustomId" v-model="queryParams.customId"
                                                placeholder="请选择客户">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0"
                                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                                        <el-form-item label="所属学校" prop="termId" class="w-100">
                                            <el-select @change="changeSchoolId" class="w-100" clearable
                                                v-model="queryParams.schoolId" placeholder="请选择学校">
                                                <el-option v-for="item in schoolList" :key="item.schoolId"
                                                    :label="item.schoolName" :value="item.schoolId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0">
                                        <el-form-item label="授课日期" prop="createTime" class="w-100">
                                            <el-date-picker class="w-100" value-format="x" clearable
                                                v-model="queryParams.createTime" type="daterange" range-separator="至"
                                                start-placeholder="开始日期" end-placeholder="结束日期" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0">
                                        <el-form-item label="所属班级" prop="classId" class="w-100">
                                            <el-select @change="changClassId" class="w-100" clearable
                                                v-model="queryParams.classId" placeholder="请选择班级">
                                                <el-option v-for="item in classList" :key="item.classId"
                                                    :label="item.className" :value="item.classId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0">
                                        <el-form-item label="授课教师" prop="teacherId" class="w-100">
                                            <el-select class="w-100" clearable v-model="queryParams.teacherId"
                                                placeholder="请选择授课老师">
                                                <el-option v-for="item in teacherList" :label="item.teacherName"
                                                    :key="item.teacherId" :value="item.teacherId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0">
                                        <el-form-item label="学科" prop="subject" class="w-100">
                                            <el-select class="w-100" clearable v-model="queryParams.subject"
                                                placeholder="请选择学科">
                                                <el-option v-for="item in subjectList" :key="item.dictKey"
                                                    :label="item.dictValue" :value="item.dictKey" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row class="">
                    <el-col :span="6" class=" mr0" v-if="role.includes(0)">
                        <el-form-item label="所属客户" prop="termId">
                            <el-select clearable @change="changeCustomId" v-model="queryParams.customId"
                                placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class=" mr0"
                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                        <el-form-item label="所属学校" prop="termId">
                            <el-select @change="changeSchoolId" style="width: 100%;" clearable
                                v-model="queryParams.schoolId" placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" class=" mr0">
                        <el-form-item label="授课日期" prop="createTime">
                            <el-date-picker style="width: 100%;" value-format="x" v-model="queryParams.createTime"
                                type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class=" mr0">
                        <el-form-item label="所属班级" prop="classId">
                            <el-select @change="changClassId" style="width: 100%;" clearable v-model="queryParams.classId"
                                placeholder="请选择班级">
                                <el-option v-for="item in classList" :key="item.classId" :label="item.className"
                                    :value="item.classId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class=" mr0">
                        <el-form-item label="授课教师" prop="teacherId">
                            <el-select style="width: 100%;" clearable v-model="queryParams.teacherId" placeholder="请选择授课老师">
                                <el-option v-for="item in teacherList" :label="item.teacherName" :key="item.teacherId"
                                    :value="item.teacherId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class=" mr0">
                        <el-form-item label="学科" prop="subject">
                            <el-select style="width: 100%;" clearable v-model="queryParams.subject" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="role.includes(0) ? 6 : role.includes(1) || role.includes(2) || role.includes(3) ? 12 : 18"
                        class="">
                        <el-button style="width:100px" class="pull-right" type="primary" @click="getList()">查找</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e mt15" style="position: relative;">
                <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt=""> -->
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="`calc(100vh - ${isSxShow ? (!(role.includes(0) || role.includes(2)) ? '265' : '265') : (!(role.includes(0) || role.includes(2)) ? '265' : '265')}px)`"
                    v-loading="loading" :data="dataList">
                    <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column align="center" width="250" label="课程名称" prop="lessonName">
                        <template #default="scope">
                            <div :title="scope.row.lessonName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.lessonName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="200" align="center" prop="subjectTxt" label="学科">
                        <template #default="scope">
                            <div :title="scope.row.subjectTxt" class="text-one">
                                {{ scope.row.subjectTxt }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" width="200" prop="className" label="授课班级">
                        <template #default="scope">
                            <div :title="scope.row.className" class="text-one">
                                {{ scope.row.className }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" prop="className" label="授课老师">
                        <template #default="scope">
                            <div :title="scope.row.teacherName" class="text-one">
                                {{ scope.row.teacherName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" prop="className" label="授课日期">
                        <template #default="scope">
                            <div class="text-one">
                                {{ timestampToTime(scope.row.createTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="附件" prop="fileCount">
                        <template #default="scope">
                            <div :title="scope.row.fileCount" style="color: #409efe;" class="text-one cursor">
                                {{ scope.row.fileCount }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" width="200" label="归属学校" prop="schoolName">
                        <template #default="scope">
                            <div :title="scope.row.schoolName" class="text-one">
                                {{ scope.row.schoolName }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column v-if="role.includes(5) || role.includes(0)" fixed="right" align="center" width="190"
                        label="编辑" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <!-- <el-button v-if="(scope.row.username == JSON.parse(this.$storage.get('userInfo')).username) || role.includes(0)" link
                                icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                            </el-button> -->
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该课堂记录" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button
                                        v-if="(scope.row.username == JSON.parse(this.$storage.get('userInfo')).username) || role.includes(0)"
                                        link icon="Delete" type="danger">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
        <el-dialog title="导入学生" v-model="scShow" width="60%">
            <el-form ref="reportForm" label-width="100px">
                <el-row>
                    <el-form-item label="所属学校">
                        <el-select @change="changeschooltList" style="width: 100%;" clearable v-model="schoolId"
                            placeholder="请选择学校">
                            <el-option v-for="item in schooltList" :key="item.schoolId" :label="item.schoolName"
                                :value="item.schoolId" />
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="所属班级">
                        <el-select v-if="!isXn" style="width: 100%;" clearable v-model="classId" placeholder="请选择学校">
                            <el-option v-for="item in classstList" :key="item.classId" :label="item.className"
                                :value="item.classId" />
                        </el-select>
                        <el-select v-if="isXn" style="width: 100%;" clearable v-model="classId" placeholder="请选择学校">
                            <el-option v-for="item in isVirtualClassList" :key="item.classId" :label="item.className"
                                :value="item.classId" />
                        </el-select>

                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="虚拟班级">
                        <el-switch @change="changeClass" v-model="isXn" />
                    </el-form-item>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center disnone" style="align-items: unset;">
                    <el-upload v-if="classId" v-model:file-list="filesList" class="upload-demo"
                        action="https://zbh.zbform.com/adminservice/zhkt/student/batchImport" :on-change="handleChange"
                        :headers="{ token }" :on-remove="handleRemove" :data="{ classId: classId }" accept=".xlsx"
                        :before-upload="beforeUpload">
                        <el-button type="primary">上 传</el-button>
                    </el-upload>
                    <el-button class="ml15" @click="scShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";
import { schoolUserAuthCondition, teacherListCondition, schoolListAuth, recordPage, classList, zhktQueryDict, schoolList, schoolDelete, teacherList, teacherDelete, recordDelete } from "@/api/adminservice/classOrganizaMain";

export default {
    name: "classRecords",
    components: {
    },
    data() {
        return {
            visible: false,
            isSxShow: true,
            timestampToTime,
            isXn: false,
            schooltList: [],
            schoolList: [],
            token: this.$storage.get("token"),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            teacherList: [],
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customId: '',
                customName: '',
                schoolId: '',
                classId: '',
                pageNum: 1,
                pageSize: 10,
                isVirtual: '',
                teacherId: '',
                createTime: [],
                subject: '',
                beginTime: '',
                endTime: '',
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            orgList: [],
            customList: [],
            classstList: [],
            isVirtualClassList: [],
            schoolId: '',
            classId: '',
            scShow: false,
            subjectList: [],
            classList: [],
            role: '',
            schoolUserList: []
        };
    },
    created() {
        this.role = this.$storage.get("role")
        schoolUserAuthCondition().then(res => {
            this.schoolUserList = res.data.data;
            if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                this.classList = res.data.data.classList;
            }
        })
        this.getZhktQueryDict();
        // this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
    },
    mounted() {
        // this.getList();
        this.getBookList();
    },
    methods: {
        changeClass() {
            this.classId = '';
        },
        changeCustomId() {
            this.queryParams.classId = '';
            this.queryParams.schoolId = '';
            this.queryParams.teacherId = '';
            this.schoolList = [];
            this.classId = [];
            this.teacherList = [];
            schoolListAuth({ customId: this.queryParams.customId ? this.queryParams.customId : this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
                // this.getList();
            });
        },
        changeSchoolId() {
            this.queryParams.classId = '';
            this.queryParams.teacherId = '';
            this.classList = [];
            this.teacherList = [];
            if (this.role.includes(0) || this.role.includes(1) || this.role.includes(2)) {
                classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
                    this.classList = res.data.data;
                    // this.getList();
                });
            } else {
                if (this.queryParams.schoolId) {
                    this.schoolUserList.forEach(e => {
                        if (e.schoolId == this.queryParams.schoolId) {
                            e.adminList.forEach(j => {
                                if (j.userId == JSON.parse(this.$storage.get('userInfo')).userId) {
                                    this.classList = j.classList;
                                }
                            })
                        }
                    })
                }
            }

        },
        changClassId() {
            this.queryParams.teacherId = '';
            this.teacherList = [];
            if (this.queryParams.classId) {
                teacherListCondition({ classId: this.queryParams.classId }).then(res => {
                    this.teacherList = res.data.data;
                    if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3) && !this.role.includes(4) && this.role.includes(5)) {
                        this.teacherList = this.teacherList.filter(itemA => {
                            return itemA.username.toString() == JSON.parse(this.$storage.get('userInfo')).username
                        })
                    }
                })
            }
        },
        changeschooltList() {
            this.classList = [];
            this.classId = '';
            classList({ schoolId: this.schoolId }).then(res => {
                this.isVirtualClassList = [];
                this.classstList = res.data.data;
                res.data.data.forEach(e => {
                    if (e.isVirtual == 1) {
                        this.isVirtualClassList.push(e)
                    }
                })
            });
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'subject' }).then(response => {
                this.subjectList = response.data.data;
            })
            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2) || this.role.includes(4))) && !this.role.includes(1) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
            });
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('020');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息技术有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
            });
            // classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
            //     this.classList = res.data.data;
            // });
        },
        excelAdd() {
            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schooltList = res.data.data;
            });
            this.schoolId = ''
            this.classId = ''
            this.scShow = true;
        },
        goDetail(row) {
            // this.$router.push({
            //     path: "/classRecordsDetail",
            //     query: {
            //         recordId: '1789198781749792768',
            //     }
            // });
            this.$router.push({
                path: "/classRecordsDetail",
                query: {
                    recordId: row.recordId,
                }
            });
        },
        downloadTemplate() {
            download('https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '导入学生模板')
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.scShow = false;
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds

        },
        changeCustom() {

        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.dataList = [];
            if (!this.role.includes(0) && !this.role.includes(1)) {

                if (this.queryParams.classId) {

                } else {
                    if (!this.queryParams.schoolId) {
                        this.$message.warning("请选择学校|班级")
                        return;
                    }
                }
            }
            if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3) && !this.role.includes(4) && this.role.includes(5)) {
                if (!this.queryParams.teacherId) {
                    this.$message.warning("请选择教师")
                    return;
                }
            }
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (this.queryParams.createTime && this.queryParams.createTime.length != 0) {
                this.queryParams.beginTime = this.queryParams.createTime[0]
                this.queryParams.endTime = this.queryParams.createTime[1]
            } else {
                this.queryParams.beginTime = ''
                this.queryParams.endTime = ''
            }
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            this.loading = true;
            var obj = JSON.parse(JSON.stringify(this.queryParams))
            if (this.queryParams.teacherId) {
                obj.customId = '';
                obj.schoolId = '';
                obj.classId = '';
            }
            recordPage(obj).then(res => {
                res.data.data.list.forEach(e => {
                    if (e.subject) {
                        e.subjectList = e.subject.split(',');
                    } else {
                        e.subjectList = [];
                    }
                    var subjectTxt = [];
                    e.subjectList.forEach(j => {
                        this.subjectList.forEach(m => {
                            if (j == m.dictKey) {
                                subjectTxt.push(m.dictValue)
                            }
                        })
                    })
                    e.subjectTxt = subjectTxt.join('、')
                })
                this.dataList = res.data.data.list;
                this.total = res.data.data.total;
                this.loading = false;
            })
            // queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
            //     this.customList = res.data.data;
            //     if (!this.$route.query.customId) {
            //         this.queryParams.customId = res.data.data[0].customId;
            //         this.changeCustom()
            //     } else {
            //     }
            // });
        },

        // 停用
        handleStop(row) {
            stopCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            recordDelete({ recordId: row.recordId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/teacherMainAdd",
                query: {
                    customId: this.queryParams.customId,
                }
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/studentMainAdd",
                query: {
                    studentId: row.studentId,
                    studentData: JSON.stringify(row)
                }
            });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}</style>