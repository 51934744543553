<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleAdd"
                        v-if="role.includes(0) || role.includes(5)">新增备课</el-button>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px">
                                <el-row class="">
                                    <el-col :span="24" class=" mr0" v-if="role.includes(0)">
                                        <el-form-item label="客户名称" prop="termId" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.customId"
                                                placeholder="请选择客户">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0">
                                        <el-form-item label="年级班制" prop="termId" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.gradeClassIsm"
                                                placeholder="请选择年级班制">
                                                <el-option v-for="item in classIsmList" :key="item.dictKey"
                                                    :label="item.dictValue" :value="item.dictKey" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="是否开放" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.isOpen"
                                                placeholder="请选择">
                                                <el-option label="是" :value="1" />
                                                <el-option label="否" :value="0" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row class="">
                    <el-col :span="8" class=" mr0" v-if="role.includes(0)">
                        <el-form-item label="客户名称" prop="termId">
                            <el-select clearable @change="getList" v-model="queryParams.customId" placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class=" mr0">
                        <el-form-item label="年级班制" prop="termId">
                            <el-select clearable @change="getList" v-model="queryParams.gradeClassIsm"
                                placeholder="请选择年级班制">
                                <el-option v-for="item in classIsmList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="是否开放">
                            <el-select clearable @change="getList" v-model="queryParams.isOpen" placeholder="请选择">
                                <el-option label="是" :value="1" />
                                <el-option label="否" :value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e" style="position: relative;">
                <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt=""> -->
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="`calc(100vh - ${isSxShow ? (!(role.includes(0) || role.includes(5)) ? '255' : '255') : (!(role.includes(0) || role.includes(5)) ? '255' : '255')}px)`"
                    v-loading="loading" :data="dataList">
                    <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column align="center" width="70" label="状态" prop="isRelease">
                        <template #default="scope">
                            <div v-if="scope.row.isRelease == 1">
                                已发布
                            </div>
                            <div v-else>
                                未发布
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="300" label="课程名称" prop="lessonName">
                        <template #default="scope">
                            <div :title="scope.row.lessonName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.lessonName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="80" label="学科" prop="subjectTxt" />
                    <el-table-column align="center" width="150" label="备课教师" prop="teacherName" />
                    <el-table-column align="center" width="120" label="教师账号" prop="username">
                        <template #default="scope">
                            {{ scope.row.username.substr(0, 3) + ' ' + scope.row.username.substr(3, 4) + ' ' +
                                scope.row.username.substr(7, 4) }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="创建时间" prop="createTime">
                        <template #default="scope">
                            {{ timestampToTime(scope.row.createTime) }}
                        </template>
                    </el-table-column>

                    <el-table-column align="center" width="300" label="所属学校" prop="schoolName">
                        <template #default="scope">
                            <div :title="scope.row.schoolName" style="" class="text-one">
                                {{ scope.row.schoolName }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" width="200" label="所属客户" prop="customName">
                        <template #default="scope">
                            <div :title="scope.row.customName" class="text-one">
                                {{ scope.row.customName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" fixed="right" label="编辑"
                        v-if="role.includes(0) || role.includes(5)">
                        <template #default="scope">
                            <el-button v-if="role.includes(0)" link icon="Edit" @click.prevent="handleUpdate(scope.row)"
                                type="primary">
                                修改
                            </el-button>
                            <el-button
                                v-if="!role.includes(0) && scope.row.username == JSON.parse(this.$storage.get('userInfo')).username"
                                link icon="Edit" @click.prevent="handleUpdate(scope.row)" type="primary">
                                修改
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该备课" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button v-if="role.includes(0)" link icon="Delete" type="danger">
                                        删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该备课" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button
                                        v-if="!role.includes(0) && scope.row.username == JSON.parse(this.$storage.get('userInfo')).username"
                                        link icon="Delete" type="danger">
                                        删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column align="left" fixed="right" width="190" label="操作"
                        v-if="role.includes(0) || role.includes(5)" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button type="primary" v-if="scope.row.isOpen == 0 && role.includes(0)"
                                @click="goOpen(scope.row, 1)">设置开放
                            </el-button>
                            <el-button type="primary" style="background: #359af2;color:#fff;"
                                v-if="scope.row.isOpen == 1 && role.includes(0)" @click="goOpen(scope.row, 0)">撤销开放
                            </el-button>
                            <el-button v-if="scope.row.isRelease == 0 && role.includes(0)" @click="handleFb(scope.row)"
                                type="primary">发布
                            </el-button>


                            <el-button type="primary"
                                v-if="scope.row.isOpen == 0 && !role.includes(0) && scope.row.username == JSON.parse(this.$storage.get('userInfo')).username"
                                @click="goOpen(scope.row, 1)">设置开放
                            </el-button>
                            <el-button type="primary" style="background: #359af2;color:#fff;"
                                v-if="scope.row.isOpen == 1 && !role.includes(0) && scope.row.username == JSON.parse(this.$storage.get('userInfo')).username"
                                @click="goOpen(scope.row, 0)">撤销开放
                            </el-button>
                            <el-button
                                v-if="scope.row.isRelease == 0 && !role.includes(0) && scope.row.username == JSON.parse(this.$storage.get('userInfo')).username"
                                @click="handleFb(scope.row)" type="primary">发布
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog :title="'云本资源'" v-model="readBook" width="60%">
            <div class="fl-warp" style="display: flex;">
                <div v-for="item in readBookList" class="csbbb">
                    <img style="width: 100px;margin-top: 10px;"
                        :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                        class="image">
                    <div style="width: 100px;text-align: center;" class="text-one mb15">{{ item.bookName }}</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public";
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";
import { zhktQueryDict, schoolList, schoolDelete, schoolListAuth, schoolUserAuthCondition } from "@/api/adminservice/classOrganizaMain";
import { lessonUpdate, lessonList, lessonDelete, lessonRelease } from "@/api/adminservice/lesson";

export default {
    name: "lessPreparatManage",
    components: {
    },
    data() {
        return {
            visible: false,
            isSxShow: true,
            timestampToTime,
            classIsmList: [],
            activeObj: {},
            subjectList: [],
            token: this.$storage.get("token"),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customId: '',
                gradeClassIsm: '',
                isOpen: '',
                isOpen: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            orgList: [],
            customList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict();
        schoolUserAuthCondition().then(res => {
            this.classList = res.data.data.classList;
        })
        this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
    },
    mounted() {
        if (this.$route.query.customId) {
            this.queryParams.customId = this.$route.query.customId
            this.changeCustom()
        } else {
        }
        this.getList();
        this.getBookList();
    },
    methods: {
        getZhktQueryDict() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('020');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息技术有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
                schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                    this.schoolList = res.data.data;
                    this.getList();
                });
            });
        },
        goDetail(row) {
            if (this.role.includes(0)) {
                this.$router.push({
                    path: "/lessPreparatDetail",
                    query: {
                        lessonId: row.lessonId,
                    }
                });
            } else if (this.role.includes(5) && row.username == JSON.parse(this.$storage.get('userInfo')).username) {
                this.$router.push({
                    path: "/lessPreparatDetail",
                    query: {
                        lessonId: row.lessonId,
                    }
                });
            } else {
                this.$router.push({
                    path: "/lessPreparatDetail",
                    query: {
                        lessonId: row.lessonId,
                        isNoSet: 1,
                    }
                });
                // this.$message.warning('当前用户没有权限')
            }

        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds

        },
        changeCustom() {
            queryTreeCustomOrg({ customId: this.queryParams.customId }).then(res => {

                this.orgList = res.data.data;
                // console.log(4343,this.findParentNode('f66df71c0ee9425d920132c40b7bba2e'))

                queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                    res.data.data.forEach(e => {
                        console.log(e.orgId)

                        console.log(this.findParentNode(e.orgId))

                        e.orgName = this.findParentNode(e.orgId).join(' -> ')
                        // console.log(434,this.findParentNode(e.orgId))
                    })
                    this.dataList = res.data.data;

                })
            })
        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            zhktQueryDict({ code: 'subject' }).then(respon => {
                this.subjectList = respon.data.data;
                lessonList({ customId: this.$storage.get('customId') }).then(res => {
                    if (this.queryParams.customId !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                        })
                    }
                    if (this.queryParams.isOpen !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.isOpen == this.queryParams.isOpen
                        })
                    }
                    if (this.role.includes(0) || this.role.includes(1)) {
                        this.dataList = res.data.data;
                    } else if (!this.role.includes(0) && !this.role.includes(1) && (this.role.includes(2) || this.role.includes(3))) {
                        this.dataList = []
                        res.data.data.forEach(e => {
                            this.schoolList.forEach(j => {
                                if (e.schoolId == j.schoolId) {
                                    this.dataList.push(e)
                                } else {
                                    if (e.username == JSON.parse(this.$storage.get('userInfo')).username) {
                                        this.dataList.push(e)
                                    }
                                }
                            })
                        })
                    } else if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                        this.dataList = []
                        res.data.data.forEach(e => {
                            if (e.username == JSON.parse(this.$storage.get('userInfo')).username) {
                                this.dataList.push(e)
                            }
                        })
                    }

                    this.dataList.forEach(e => {
                        this.subjectList.forEach(j => {
                            if (e.subject == j.dictKey) {
                                e.subjectTxt = j.dictValue;
                            }
                        })
                    })
                    if (this.queryParams.gradeClassIsm !== '') {
                        this.dataList = this.dataList.filter(itemA => {
                            return itemA.gradeClassIsmList.indexOf(this.queryParams.gradeClassIsm) != -1
                        })
                    }
                    if ((!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3) && !this.role.includes(4)) && this.role.includes(5)) {
                        this.dataList = this.dataList.filter(itemA => {
                            return itemA.username.toString() == JSON.parse(this.$storage.get('userInfo')).username
                        })
                    }
                    // if (!this.$route.query.customId) {
                    //     this.queryParams.customId = res.data.data[0].customId;
                    //     this.changeCustom()
                    // } else {
                    // }
                });
            })

        },

        // 停用
        handleStop(row) {
            stopCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        goOpen(row, type) {
            console.log(row);
            this.activeObj = row;
            this.activeObj.isOpen = type;
            lessonUpdate(this.activeObj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("设置成功");
                    this.getList()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        handleFb(row) {
            lessonRelease({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            lessonDelete({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            if (this.role.includes(1)) {
                var st = Number(this.$storage.get('020StTime'))
                var end = Number(this.$storage.get('020EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.$router.push({
                path: "/lessPreparatAdd",
                query: {
                    // customId: this.queryParams.customId,
                }
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/lessPreparatAdd",
                query: {
                    lessonId: row.lessonId,
                }
            });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}</style>