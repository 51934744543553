<template>
    <div>
        <div tabindex="-1" class="el-image-viewer__wrapper" style="z-index: 2201;">
            <div class="el-image-viewer__mask"></div><!-- CLOSE --><span @click="closedd"
                class="el-image-viewer__btn el-image-viewer__close"><i class="el-icon"><svg viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                    </svg></i></span><!-- ARROW --><span v-if="current != 0" @click="lastPage"
                class="el-image-viewer__btn el-image-viewer__prev"><i class="el-icon"><svg viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z">
                        </path>
                    </svg></i></span><span @click="nextPage" v-if="current != strokeList.length - 1"
                class="el-image-viewer__btn el-image-viewer__next"><i class="el-icon"><svg viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z">
                        </path>
                    </svg></i></span><!-- ACTIONS -->
            <!-- <div class="el-image-viewer__btn el-image-viewer__actions">
            <div class="el-image-viewer__actions__inner"><i class="el-icon"><svg viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zM352 448h256a32 32 0 0 1 0 64H352a32 32 0 0 1 0-64z">
                        </path>
                    </svg></i><i class="el-icon"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z">
                        </path>
                    </svg></i><i class="el-image-viewer__actions__divider"></i><i class="el-icon"><svg
                        viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="m160 96.064 192 .192a32 32 0 0 1 0 64l-192-.192V352a32 32 0 0 1-64 0V96h64v.064zm0 831.872V928H96V672a32 32 0 1 1 64 0v191.936l192-.192a32 32 0 1 1 0 64l-192 .192zM864 96.064V96h64v256a32 32 0 1 1-64 0V160.064l-192 .192a32 32 0 1 1 0-64l192-.192zm0 831.872-192-.192a32 32 0 0 1 0-64l192 .192V672a32 32 0 1 1 64 0v256h-64v-.064z">
                        </path>
                    </svg></i><i class="el-image-viewer__actions__divider"></i><i class="el-icon"><svg
                        viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M289.088 296.704h92.992a32 32 0 0 1 0 64H232.96a32 32 0 0 1-32-32V179.712a32 32 0 0 1 64 0v50.56a384 384 0 0 1 643.84 282.88 384 384 0 0 1-383.936 384 384 384 0 0 1-384-384h64a320 320 0 1 0 640 0 320 320 0 0 0-555.712-216.448z">
                        </path>
                    </svg></i><i class="el-icon"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M784.512 230.272v-50.56a32 32 0 1 1 64 0v149.056a32 32 0 0 1-32 32H667.52a32 32 0 1 1 0-64h92.992A320 320 0 1 0 524.8 833.152a320 320 0 0 0 320-320h64a384 384 0 0 1-384 384 384 384 0 0 1-384-384 384 384 0 0 1 643.712-282.88z">
                        </path>
                    </svg></i></div>
        </div> -->
            <!-- CANVAS -->
            <div class="el-image-viewer__canvas">
                <view style="top: 0%;position: absolute;font-size: 18px;color: #fff;line-height: 7.5vh;">
                    预览（{{ current + 1 }}/{{ strokeList.length }}）</view>

                <div ref="codeImgRef" class="dcCanvas"
                    :style="'height:' + canvasHeight + 'px;width:' + canvasWidth + 'px;position: absolute;'"
                    v-for="(item, index) in strokeList.length">

                    <img :style="'pointer-events: none;position:absolute;left:0;border:1px solid #eeeeee;border-radius:10px;width:' + canvasWidth + 'px;height:' + canvasHeight + 'px;'"
                        crossOrigin="anonymous" :src="bookData.formUrl + '/' + (index + 1) + '.png?_=' + Date.now()">
                    <canvas :width="canvasWidth * smScale" :height="canvasHeight * smScale" type="2d"
                        :id="'mineCanvasExport' + index"
                        :style="'pointer-events: none;position: absolute;left:0;width:' + canvasWidth + 'px;height:' + canvasHeight + 'px;z-index: 1000;'">
                    </canvas>
                    <img v-if="isShow" v-for="items in strokeList[index].illustrationList" crossOrigin="anonymous"
                        :style="'width: ' + items.w0 + 'px;height: ' + items.h0 + 'px;position: absolute;left: ' + items.x0 + 'px;top: ' + items.y0 + 'px;transform: rotate(' + items.rotate + 'deg);z-index:' + items.z + ';pointer-events: none;'"
                        :src="items.illustrationUrl + '?_=' + Date.now()">
                    <div v-if="isShow" v-for="items in formDesignData[index]"
                        :style="'width: ' + items.w0 + 'px;height: ' + items.h0 + 'px;position: absolute;left: ' + items.x0 + 'px;top: ' + items.y0 + 'px;z-index:999;pointer-events: none;overflow:hidden;font-size:14px;text-align:left;'">
                        {{ items.value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import { timestampToTime } from "@/utils/date";

export default {
    name: 'canvasExport',
    props: {
        strokeList: {
            type: Array,
            default: []
        },
        bookData: {
            type: Object,
            default: {}
        },
        formDesignData: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            canvasHeight: 0,
            scale: 1,
            smScale: 2,
            current: 0,
            ctxList: [],
            clickPage: [],
            isShow: false,
        }
    },
    created() {
        // 监听网页大小
        // this.bookData = JSON.parse(this.$storage.get("zsdata"));
        // this.strokeList = JSON.parse(this.$storage.get("zsdata")).strokeList;
    },
    mounted() {
        console.log(this.bookData)
        console.log(this.formDesignData);
        console.log('strokeList', this.strokeList)
        this.canvasHeight = window.innerHeight * (8.5 / 10);
        this.canvasWidth = this.canvasHeight * this.bookData.pageWidth / this.bookData.pageHeight;
        this.scale = (this.canvasWidth / this.bookData.pageWidth / 8 * 0.3)
        this.strokeList.forEach(e => {
            if (e.illustrationList) {
                e.illustrationList.forEach(m => {
                    m.x0 = m.x / this.bookData.pageWidth * this.canvasWidth;
                    m.y0 = m.y / this.bookData.pageHeight * this.canvasHeight;
                    m.w0 = m.width / this.bookData.pageWidth * this.canvasWidth;
                    m.h0 = m.height / this.bookData.pageHeight * this.canvasHeight;
                })
            }

        })
        this.formDesignData.forEach(e => {
            e.forEach(m => {
                m.x0 = m.x / this.bookData.pageWidth * this.canvasWidth;
                m.y0 = m.y / this.bookData.pageHeight * this.canvasHeight;
                m.w0 = m.width / this.bookData.pageWidth * this.canvasWidth;
                m.h0 = m.height / this.bookData.pageHeight * this.canvasHeight;
            })
        })
        this.isShow = true

        this.startCanvas()
    },
    methods: {
        copyCodeImg() {
            let pdf = new jsPDF('p', 'mm', 'a4');
            let uuid = this.uuid()
            this.$refs.codeImgRef.forEach((e, i) => {
                html2canvas(e, {
                    backgroundColor: "#ffffff",
                    useCORS: true,
                    scale: 2,
                }).then(async canvas => {
                    let pageData = canvas.toDataURL('image/jpeg');
                    // // 生成图片地址
                    // var imgUrl = canvas.toDataURL('image/png');
                    // // 创建一个a标签，用来下载图片
                    // var eleLink = document.createElement('a');
                    // // // 转换后的图片地址
                    // eleLink.href = imgUrl;
                    // // // 文件名设置
                    // eleLink.download = '默认文件名';
                    // // // 下载
                    // eleLink.click();
                    this.canvasHeight, this.canvasWidth
                    if (this.canvasWidth > this.canvasHeight) {
                        var xxx = 210 / this.canvasWidth
                        var ch = xxx * this.canvasHeight
                        pdf.addImage(pageData, 'JPEG', 0, 0, 210, ch)
                    } else {
                        var xxx = 297 / this.canvasHeight
                        var cw = xxx * this.canvasWidth
                        pdf.addImage(pageData, 'JPEG', 0, 0, cw, 297)
                    }
                    if (i == this.strokeList.length - 1) {
                        console.log(112233)
                        pdf.save(this.bookData.fileName + '-' + timestampToTime(this.bookData.createTime) + '.pdf');
                    } else {
                        pdf.addPage()
                    }
                })
            })
            this.$emit('canvasClose', true)
        },

        uuid() {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4";   // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);   // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";
            return s.join("");
        },
        closedd() {
            this.$emit('canvasClose', true)
        },
        lastPage() {
            if (this.current > 0) {
                this.current--;
                this.translateX = this.current * this.swiperWidth;
                if (this.clickPage.indexOf(this.current) === -1) {
                    this.canvasToStrokePointList(this.current)
                }
            }
        },
        nextPage() {
            if (this.current < this.strokeList.length - 1) {
                this.current++;
                this.translateX = this.current * this.swiperWidth;
                if (this.clickPage.indexOf(this.current) === -1) {
                    this.canvasToStrokePointList(this.current)
                }
            }
        },
        // 监听网页大小
        watchWidth() {
        },
        // 画布初始化
        startCanvas() {
            for (var i = 0; i < this.strokeList.length; i++) {
                var canvas = document.getElementById("mineCanvasExport" + i);
                // 获取在 canvas 上绘图的 canvasRenderingContent2D 对象
                var ctx = canvas.getContext("2d");
                this.ctxList[i] = ctx;
                this.ctxList[i].strokeStyle = this.$store.state.zdy.activeColor;
                this.ctxList[i].lineWidth = (this.$store.state.zdy.activeLineWidth / this.bookData.pageWidth * this.canvasWidth / 3);
                this.ctxList[i].lineCap = 'round';
                this.ctxList[i].lineJoin = 'round';
            }
            setTimeout(() => {
                for (var i = 0; i < this.strokeList.length; i++) {
                    this.canvasToStrokePointList(i)
                }
                setTimeout(() => {
                    this.copyCodeImg()
                }, 2000)
            }, 1000)
        },
        //Canvas字符串转化为笔迹点列表
        canvasToStrokePointList(index) {
            try {
                this.clickPage.push(index)
                let list = this.emptyRepeatStroke(this.strokeList[index].strokeList);

                list.forEach((j, k) => {
                    this.ctxList[index].strokeStyle = (j.c)
                    this.ctxList[index].lineWidth = (j.t / this.bookData.pageWidth * this.canvasWidth / 15)

                    this.getPoints(index, k, j.p, index)
                })
            } catch (e) {
                //TODO handle the exception
            }
        },
        // 去除重复笔迹
        emptyRepeatStroke(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.p) && res.set(arr.p, 1))
        },
        // 笔迹转点
        getPoints(m, n, svgPointsPath, pageID) {
            var points = [];
            var dataArray = svgPointsPath.split("l");
            if (dataArray.length == 2) {
                var firstPontArray = dataArray[0].replace(/M/, "").split(" ");
                var firstPointX = Math.round(firstPontArray[0].trim());
                var firstPointY = Math.round(firstPontArray[1].trim());
                points.push({
                    x: firstPointX,
                    y: firstPointY
                })
                var pointsArray = dataArray[1].replace("l", "").replace(/-/g, " -").trim().split(" ");
                for (var i = 0; i < pointsArray.length; i++) {
                    if ((i + 1) < pointsArray.length && i % 2 == 0) {
                        firstPointX = Math.round(firstPointX) + Math.round(pointsArray[i].trim());
                        firstPointY = Math.round(firstPointY) + Math.round(pointsArray[i + 1].trim());
                        points.push({
                            x: firstPointX,
                            y: firstPointY
                        })
                    }

                }
            }
            this.strokeList[m].strokeList[n].stList = points;
            this.drowStroke(points, pageID)
        },
        // 点集绘制笔记
        drowStroke(pointList, pageID) {
            this.ctxList[pageID].beginPath();

            let pointLast = {};
            let pointNext = {};
            if (pointList.length > 1) {
                for (var i = 0; i < pointList.length; i++) {
                    if ((i + 1) < pointList.length) {
                        pointLast = pointList[i];
                        pointNext = pointList[i + 1];
                    } else {
                        pointLast = pointList[i - 1];
                        pointNext = pointList[i];
                    }
                    // 笔记粗细
                    // this.ctx2.lineWidth(1);
                    let pX1 = pointLast.x * this.scale * this.smScale;
                    let pY1 = pointLast.y * this.scale * this.smScale;
                    let pX2 = pointNext.x * this.scale * this.smScale;
                    let pY2 = pointNext.y * this.scale * this.smScale;
                    if (i === 0) {
                        this.ctxList[pageID].moveTo(pX1, pY1);
                    } else {
                        // 笔迹
                        this.ctxList[pageID].moveTo(pX1, pY1);
                        this.ctxList[pageID].lineTo(pX2, pY2);
                    }
                }
            }
            this.ctxList[pageID].stroke();
        },
    }
}
</script>

<style scoped>
.bgbbb {
    /* background-color: #fff; */
    z-index: 999;
    position: absolute;
}

.op0 {
    opacity: 0;
}

.op1 {
    opacity: 1;
}
</style>