<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="8" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增场景</el-button>
                </el-col>
                <el-col :span="16" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-row class="mt15">
                                <el-col :span="24" class="mr0">
                                    <el-form-item label="场景名称" class="w-100">
                                        <el-input clearable v-model="queryParams.sceneName" placeholder="请输入场景名称"
                                            class="w-100" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <div style="margin:0 auto;">
                                    <el-button @click="handleQuery()" type="primary">查找</el-button>
                                </div>
                            </el-row>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                    <!-- <el-form-item class="pull-right">
                        <el-input v-model="queryParams.sceneName" placeholder="请输入场景名称" class="input-with-select">
                            <template #append>
                                <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                            </template>
                        </el-input>
                    </el-form-item> -->
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-row>
                <el-radio-group v-model="cardType" class="mt15">
                    <el-radio-button label="场景" />
                    <el-radio-button label="素材" />
                    <el-radio-button label="成员" />
                </el-radio-group>
            </el-row> -->
            <div class="bre9e">
                <el-table header-cell-class-name="bgf2" @current-change="handleCurrentChange" highlight-current-row
                    class="sssstt" height="calc(100vh - 255px)" v-loading="loading" :data="dataList">
                    <el-table-column align="center" type="index" label="序号" width="60" />
                    <el-table-column align="center" width="250px" label="场景名称" prop="sceneName">
                        <template #default="scope">
                            <div :title="scope.row.sceneName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.sceneName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="120px" label="管理员账号" prop="adminAccount" />
                    <el-table-column align="center" width="120px" label="管理员姓名" prop="adminName" />
                    <el-table-column align="center" width="300px" label="场景描述" prop="sceneDescription">
                        <template #default="scope">
                            <div :title="scope.row.sceneDescription" class="text-one">
                                {{ scope.row.sceneDescription }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="90px" label="场景类型" prop="sceneType">
                        <template #default="scope">
                            <div class="text-one">
                                {{ scope.row.sceneType == 1 ? '明信片' : '表单' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="120px" label="记录数" prop="recordAmount" />
                    <el-table-column align="center" width="200px" label="分享标题" prop="shareTitle">
                        <template #default="scope">
                            <div :title="scope.row.shareTitle" class="text-one">
                                {{ scope.row.shareTitle }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="80px" label="图片数" prop="imgCount">
                        <template #default="scope">
                            <div style="display: flex;justify-content:center;">
                                <el-button @click="goPic(scope.row)" type="primary">{{ scope.row.imgCount }}</el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="80px" label="视频数" prop="videoCount">
                        <template #default="scope">
                            <div style="display: flex;justify-content:center;">
                                <el-button @click="goVideo(scope.row)" type="primary">{{ scope.row.videoCount }}</el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="80px" label="成员数" prop="memberCount">
                        <template #default="scope">
                            <el-button @click="goPeople(scope.row)" type="primary">{{ scope.row.memberCount }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" fixed="right" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">编辑
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该场景" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button link icon="Delete" type="danger">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-dialog :title="'素材'" v-model="scOpen" width="70%">
            <el-col class="custom">
                <el-table ref="formtabledetail" border :data="scList" style="width: 100%" height="50vh">
                    <el-table-column align="center" type="index" label="序号" width="60" />
                    <el-table-column align="center" width="150" label="素材名称" prop="resourceName">
                        <template #default="scope">
                            <div :title="scope.row.resourceName" class="text-one">
                                {{ scope.row.resourceName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="素材详情" prop="resourceUrl">
                        <template #default="scope">
                            <div>
                                <img class="cursor" v-if="scope.row.resourceType == 0" style="width: 100px;height:auto"
                                    @click="goPicVierer(scope.row)" :src="scope.row.resourceUrl + '_original'" alt="">
                                <img class="cursor" v-else style="width: 100px;height:auto" @click="downVideo(scope.row)"
                                    :src="scope.row.resourceUrl + '?x-oss-process=video/snapshot,t_1,m_fast,_=' + Date.now()"
                                    alt="">
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" width="" label="素材说明" prop="description" />
                    <!-- <el-table-column align="center" width="100" label="点阵地址" prop="recordCount" /> -->
                </el-table>
            </el-col>
        </el-dialog>
        <el-dialog :title="'成员'" v-model="cyOpen" width="50%">
            <el-col class="custom">
                <el-table ref="formtabledetail" border :data="mbList" style="width: 100%" height="50vh">
                    <el-table-column align="center" type="index" label="序号" width="60" />
                    <el-table-column align="center" width="120" label="成员账号" prop="memberAccount" />
                    <el-table-column align="center" width="120" label="成员名称" prop="memberName" />
                    <el-table-column align="center" width="" label="地址" prop="memberAddress" />
                </el-table>
            </el-col>
        </el-dialog>
        <el-image-viewer v-if="isOpen" :url-list="activeimgs" @close="closePic" />
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { queryScene, sceneDelete, sceneInfo } from "@/api/adminservice/scene";
import { qunQueryUser } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";

export default {
    name: "postScenarioMain",
    components: {
    },
    data() {
        return {
            visible: false,
            activeimgs: [],
            isOpen: false,
            scOpen: false,
            cyOpen: false,
            scList: [],
            mbList: [],
            cardType: '场景',
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [
            ],
            // 查询参数
            queryParams: {
                sceneName: '',
                sceneType: ''
            },
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: {},
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getList();
    },
    activated() {
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        downVideo(row) {
            console.log(row);
            download(row.resourceUrl, row.resourceName)
        },
        goPicVierer(row) {
            this.activeimgs = [];
            this.activeimgs.push(row.resourceUrl + '_original');
            this.isOpen = true;
            console.log(row);
        },
        closePic() {
            this.isOpen = false;
        },
        goPic(row) {
            console.log(row);
            sceneInfo({ sceneId: row.sceneId }).then(res => {
                this.scList = res.data.data.imgList
                this.scOpen = true
            })
        },
        goVideo(row) {
            sceneInfo({ sceneId: row.sceneId }).then(res => {
                this.scList = res.data.data.videoList
                this.scOpen = true
            })
        },
        goPeople(row) {
            sceneInfo({ sceneId: row.sceneId }).then(res => {
                this.mbList = res.data.data.memberList
                this.cyOpen = true
            })
        },
        validateUsername(value,) {
            qunQueryUser({ mobile: value.memberAccount }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.memberAccount = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    value.memberName = res.data.data.nickName
                    value.memberUserId = res.data.data.userId
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                //     if (this.queryParams.status !== '') {
                //         res.data.data = res.data.data.filter(itemA => {
                //             return itemA.customStatus == this.queryParams.status
                //         })
                //     }
                    if (this.queryParams.sceneName !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.sceneName.indexOf(this.queryParams.sceneName) != -1
                        })
                    }
                //     if (this.queryParams.customId !== '') {
                //         res.data.data = res.data.data.filter(itemA => {
                //             return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                //         })
                //     }
                //     if (this.queryParams.sceneType !== '') {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType == 1;
                })
                //     }
                //     res.data.data.forEach((e, i) => {
                //         e.beginDate = timestampToTime(e.beginDate).substring(0, 10)
                //         e.endDate = timestampToTime(e.endDate).substring(0, 10)
                //     })
                this.dataList = res.data.data;
                this.dataList.sort(function (a, b) {
                    if (a.createTime > b.createTime) { return -1; }
                    if (a.createTime < b.createTime) { return 1; }
                    return 0;
                })
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            sceneDelete({ sceneId: row.sceneId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/postScenarioDetail",
                query: {
                    sceneId: row.sceneId,
                }
            });
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 新增按钮操作 */
        handleAdd() {
            if (this.$storage.get('role').includes(1)) {
                var st = Number(this.$storage.get('050StTime'))
                var end = Number(this.$storage.get('050EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.$router.push({
                path: "/postScenarioAdd",
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.$router.push({
                path: "/postScenarioAdd",
                query: {
                    sceneId: row.sceneId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}
</style>