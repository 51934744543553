<template>
    <div class="app-container" @click="visible = false;">
        <el-card shadow="never" style="position: relative;" v-loading="isSBBolean && { text: loadTxt }"
            :element-loading-text="loadTxt">
            <el-row>
                <el-col :span="24" class="mr0 mb0">
                    <template v-if="active == 2">
                        <el-button @click="identifyAll">识别</el-button>
                        <el-button @click="confirmAll" type="primary">确认</el-button>
                        <el-button @click="delAll" type="danger">删除</el-button>
                    </template>
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="getList">
                                <el-row>
                                    <el-col>
                                        <div class="chosebtn pull-right">
                                            <div>
                                                <img @click="changeActive(1)" :class="active == 1 ? 'bor-lan' : ''"
                                                    src="@/assets/img/tr-card.png" alt="">
                                            </div>
                                            <div>
                                                <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''"
                                                    src="@/assets/img/tr-table.png" alt="">
                                            </div>
                                            <div :class="sortName != '' ? 'bor-lan' : ''">
                                                <el-popover placement="bottom-start" :width="125" trigger="hover"
                                                    :min-width="'auto'">
                                                    <div class="pb10" style="text-align: center;">排序</div>
                                                    <div class="pxbutton" @click="changeSortName('采集人员')"
                                                        :style="sortName == '采集人员' ? 'background: #21ac74;color:#fff;' : ''">
                                                        采集人员
                                                    </div>
                                                    <div class="pxbutton" @click="changeSortName('采集时间')"
                                                        :style="sortName == '采集时间' ? 'background: #21ac74;color:#fff;' : ''">
                                                        采集时间
                                                    </div>
                                                    <template #reference>
                                                        <el-icon :size="20" style="width: 30px;height:30px"
                                                            @click="setSort">
                                                            <Sort />
                                                        </el-icon>
                                                    </template>
                                                </el-popover>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <div class="txt-ct">
                                    <el-divider />
                                </div>
                                <el-row class="mt15">
                                    <el-col :span="24" class="" v-if="role.includes(0)">
                                        <el-form-item label="归属客户" class="w-100">
                                            <el-select filterable clearable class="w-100" @change="changeCustom"
                                                v-model="queryParams.customId" placeholder="请选择">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="应用场景" class="w-100">
                                            <el-select filterable clearable class="w-100" @change="changeScene"
                                                v-model="queryParams.sceneId" placeholder="请选择">
                                                <el-option v-for="item in sceneList" :key="item.sceneId"
                                                    :label="item.customAbbreviation + ' | ' + item.sceneName"
                                                    :value="item.sceneId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <!-- <el-col :span="24" class="">
                                        <el-form-item label="删除状态" class="w-100">
                                            <el-select filterable clearable class="w-100" @change="changeList"
                                                v-model="queryParams.isDelete" placeholder="请选择">
                                                <el-option label="未删除" :value="0" />
                                                <el-option label="已删除" :value="1" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col> -->
                                    <el-col :span="24" class="">
                                        <el-form-item label="表单名称" class="w-100">
                                            <el-select clearable @change="changeList" class="w-100"
                                                v-model="queryParams.formId" placeholder="请选择">
                                                <el-option v-for="item in formList" :key="item.formId"
                                                    :label="item.customAbbreviation + ' | ' + item.formName"
                                                    :value="item.formId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="采集人员" class="w-100">
                                            <el-select filterable multiple clearable class="w-100" @change="changeList"
                                                v-model="queryParams.userIdList" placeholder="请选择">
                                                <el-option v-for="item in peopleList"
                                                    :label="item.memberName + '  ' + item.memberAccount"
                                                    :value="item.memberUserId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="zdp">
                                        <el-form-item label="采集时间" class="w-100">
                                            <el-date-picker class="w-100" value-format="x" @change="changeList"
                                                v-model="queryParams.createTime" type="datetimerange" range-separator="至"
                                                :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]"
                                                start-placeholder="开始时间" end-placeholder="结束时间" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="记录状态" class="w-100">
                                            <el-select filterable multiple clearable class="w-100" @change="changeList"
                                                v-model="queryParams.finishtxtStutasList" placeholder="请选择">
                                                <el-option label="填报" :value="0" />
                                                <el-option label="已识别" :value="1" />
                                                <el-option label="已确认" :value="2" />
                                                <el-option label="已删除" :value="3" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
            <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-20px);" class="sxShowcss"
                src="../../assets/img/arrow-down.png" alt="">
            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="getList">
                <el-row class="mt15" v-if="isFilter">
                    <el-col :span="6" class="" v-if="role.includes(0)">
                        <el-form-item label="归属客户">
                            <el-select filterable clearable @change="changeCustom" v-model="queryParams.customId"
                                placeholder="请选择">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="应用场景">
                            <el-select filterable clearable @change="changeScene" v-model="queryParams.sceneId"
                                placeholder="请选择">
                                <el-option v-for="item in sceneList" :key="item.sceneId"
                                    :label="item.customAbbreviation + ' | ' + item.sceneName" :value="item.sceneId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="完成状态">
                            <el-select filterable clearable @change="changeList" v-model="queryParams.isFinish"
                                placeholder="请选择">
                                <el-option label="未完成" :value="0" />
                                <el-option label="已完成" :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="删除状态">
                            <el-select filterable clearable @change="changeList" v-model="queryParams.isDelete"
                                placeholder="请选择">
                                <el-option label="未删除" :value="0" />
                                <el-option label="已删除" :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="表单名称">
                            <el-select clearable @change="changeList" v-model="queryParams.formId" placeholder="请选择">
                                <el-option v-for="item in formList" :key="item.formId"
                                    :label="item.customAbbreviation + ' | ' + item.formName" :value="item.formId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="采集人员">
                            <el-select filterable clearable @change="changeList" v-model="queryParams.userId"
                                placeholder="请选择">
                                <el-option v-for="item in peopleList" :label="item.memberName + '  ' + item.memberAccount"
                                    :value="item.memberUserId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="zdp">
                        <el-form-item label="采集时间">
                            <el-date-picker style="width: 100%;" value-format="x" @change="changeList"
                                v-model="queryParams.createTime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-button style="width:100px" class="pull-right" type="primary" @click="getList()">查找</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <div class="chosebtn pull-right">
                            <div>
                                <img @click="changeActive(1)" :class="active == 1 ? 'bor-lan' : ''"
                                    src="@/assets/img/tr-card.png" alt="">
                            </div>
                            <div>
                                <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''"
                                    src="@/assets/img/tr-table.png" alt="">
                            </div>
                            <div :class="sortName != '' ? 'bor-lan' : ''">
                                <el-popover placement="bottom-start" :width="125" trigger="hover" :min-width="'auto'">
                                    <div class="pb10" style="text-align: center;">排序</div>
                                    <div class="pxbutton" @click="changeSortName('采集人员')"
                                        :style="sortName == '采集人员' ? 'background: #21ac74;color:#fff;' : ''">
                                        采集人员
                                    </div>
                                    <div class="pxbutton" @click="changeSortName('采集时间')"
                                        :style="sortName == '采集时间' ? 'background: #21ac74;color:#fff;' : ''">
                                        采集时间
                                    </div>
                                    <template #reference>
                                        <el-icon :size="20" style="width: 30px;height:30px" @click="setSort">
                                            <Sort />
                                        </el-icon>
                                    </template>
                                </el-popover>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->

            <template v-if="active == 1">
                <el-row :style="'max-height:calc(100vh - ' + (isSxShow ? '237' : '210') + 'px);overflow-y: auto;'"
                    v-loading="loading">
                    <el-col v-if="groupName == ''" class="w-90" v-for="(item, index) in dataList" :key="index" :xs="12"
                        :sm="12" :md="8">
                        <el-card @click="goDetail(item)" :body-style="{ padding: '0px' }">
                            <div style="display: flex;">
                                <div style="padding:14px 0px 14px 14px;width: 100px;">
                                    <!-- <img style="width:100px" :src="item.formUrl + '/1.png'" alt="" class="image"> -->
                                    <Canvasbox v-if="isCanvas" :bookData="item" :imgList="[]" :imwidth="100"
                                        :imheight="item.pageHeight / item.pageWidth * 100"
                                        :canvasid="item.recordId + '22333' + index" :picSrc="item.formUrl + '/1.png'"
                                        :detail="item.strokeList">
                                    </Canvasbox>
                                </div>
                                <div style="padding: 16px 14px 14px 14px" class="fs-14 wbba">
                                    <div style="display: flex;" class="mb5">
                                        <div class="wh-sp-now">表单名称：</div>
                                        <div class="">{{ item.fileName }}</div>
                                    </div>
                                    <div style="display: flex;" class="mb5">
                                        <div class="wh-sp-now">采集人员：</div>
                                        <div class="" v-if="item.member">{{
                                            item.member.memberName ? item.member.memberName : item.member.memberAccount }}
                                        </div>
                                    </div>
                                    <div style="display: flex;" class="mb5">
                                        <div class="wh-sp-now">采集时间：</div>
                                        <div class="">{{ timestampToTime(item.createTime) }}</div>
                                    </div>
                                    <div style="display: flex;" class="mb5" v-if="role.includes(0)">
                                        <div class="wh-sp-now">归属客户：</div>
                                        <div class="">{{ item.customName }}</div>
                                    </div>
                                    <div style="display: flex;" class="mb5">
                                        <div class="wh-sp-now">应用场景：</div>
                                        <div class="">{{ item.sceneName }}</div>
                                    </div>
                                    <div style="display: flex;" class="mb5">
                                        <div class="wh-sp-now" style="width: 70px;text-align: right;">状态：</div>
                                        <div style="color: #c45656;" v-if="item.finishName == '已删除'">已删除</div>
                                        <div style="color: rgb(40 12 175);"
                                            v-if="item.isFinish === 0 && item.finishName == '填报'">填报</div>
                                        <div style="color: #eea507;" v-if="item.finishName == '已识别'">已识别
                                        </div>
                                        <div style="color: #048d17;" v-if="item.finishName == '已确认'">已确认
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <div v-if="groupName != ''" style="width: 100%;">
                        <el-collapse>
                            <el-collapse-item :title="items" :name="indexs" v-for="(items, indexs) in collapseList">
                                <div style="display: flex;flex-wrap: wrap;position: relative;box-sizing: border-box;">
                                    <el-col class="w-90" v-for="(item, index) in showObj[items]" :key="index" :xs="12"
                                        :sm="12" :md="8">
                                        <el-card @click="goDetail(item)" :body-style="{ padding: '0px' }">
                                            <div style="display: flex;">
                                                <div style="padding:14px 0px 14px 14px;width: 100px;">
                                                    <!-- <img style="width:100px" :src="item.formUrl + '/1.png'" alt=""
                                                        class="image"> -->
                                                    <Canvasbox v-if="isCanvas" :bookData="item" :imgList="[]" :imwidth="100"
                                                        :imheight="item.pageHeight / item.pageWidth * 100"
                                                        :canvasid="item.recordId + '2233311111' + index"
                                                        :picSrc="item.formUrl + '/1.png'" :detail="item.strokeList">
                                                    </Canvasbox>
                                                </div>
                                                <div style="padding: 16px 14px 14px 14px" class="fs-14 wbba">
                                                    <div style="display: flex;" class="mb5">
                                                        <div class="wh-sp-now">表单名称：</div>
                                                        <div class="">{{ item.fileName }}</div>
                                                    </div>
                                                    <div style="display: flex;" class="mb5">
                                                        <div class="wh-sp-now">采集人员：</div>
                                                        <div class="" v-if="item.member">{{
                                                            item.member.memberName ? item.member.memberName :
                                                            item.member.memberAccount
                                                        }}</div>
                                                    </div>
                                                    <div style="display: flex;" class="mb5">
                                                        <div class="wh-sp-now">采集时间：</div>
                                                        <div class="">{{ timestampToTime(item.createTime) }}</div>
                                                    </div>
                                                    <div style="display: flex;" class="mb5" v-if="role.includes(0)">
                                                        <div class="wh-sp-now">归属客户：</div>
                                                        <div class="">{{ item.customName }}</div>
                                                    </div>
                                                    <div style="display: flex;" class="mb5">
                                                        <div class="wh-sp-now">应用场景：</div>
                                                        <div class="">{{ item.sceneName }}</div>
                                                    </div>
                                                    <div style="display: flex;" class="mb5">
                                                        <div class="wh-sp-now" style="width: 70px;text-align: right;">状态：
                                                        </div>
                                                        <div style="color: #c45656;" v-if="item.finishName == '已删除'">已删除
                                                        </div>
                                                        <div style="color: rgb(40 12 175);"
                                                            v-if="item.isFinish === 0 && item.finishName == '填报'">填报</div>
                                                        <div style="color: #eea507;" v-if="item.finishName == '已识别'">已识别
                                                        </div>
                                                        <div style="color: #048d17;" v-if="item.finishName == '已确认'">已确认
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </el-card>
                                    </el-col>
                                </div>

                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <el-empty style="margin: 0 auto;padding-top: 10vh;" v-if="dataList.length == 0" :image-size="200" />
                </el-row>
            </template>
            <template v-if="active == 2">
                <div class="bre9e mt15">
                    <el-table @selection-change="handleSelectionChange" header-cell-class-name="bgf2" v-loading="loading"
                        v-if="groupName == ''" show-overflow-tooltip class="sssstt"
                        :height="'calc(100vh - ' + (isSxShow ? '255' : '225') + 'px)'" :data="dataList"
                        highlight-current-row>
                        <el-table-column align="center" type="selection" :selectable="selectable" width="55" />
                        <!-- <el-table-column align="center" type="selection" width="55" /> -->
                        <el-table-column align="center" fixed="left" type="index" label="序号" width="60">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)" style="">{{ scope.row.index + 1 }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="150" label="记录标题" prop="recordName">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)" :title="scope.row.recordName" class="text-one"
                                    style="">{{ scope.row.recordName }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="150" sortable label="采集时间" prop="createTime">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)" style="">{{ timestampToTime(scope.row.createTime) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="100" sortable label="采集人员" prop="memberName">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)" :title="scope.row.memberName" class="text-one"
                                    style="">{{ scope.row.memberName }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="90" label="状态" prop="isFinish">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)" style="color: #c45656;"
                                    v-if="scope.row.finishName == '已删除'">已删除</div>
                                <div :class="returnClass(scope.row)" style="color: rgb(40 12 175);"
                                    v-if="scope.row.finishName == '填报'">填报</div>
                                <div :class="returnClass(scope.row)" style="color: #eea507;"
                                    v-if="scope.row.finishName == '已识别'">已识别</div>
                                <div :class="returnClass(scope.row)" style="color: #048d17;"
                                    v-if="scope.row.finishName == '已确认'">已确认</div>
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="350" label="表单名称" prop="fileName">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)" class="text-one" :title="scope.row.fileName" style="">
                                    {{ scope.row.fileName }}</div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column align="center" width="200" label="应用场景" prop="sceneName">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)" style="" class="text-one" :title="scope.row.sceneName">
                                    {{ scope.row.sceneName }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="200" label="归属客户" prop="customName" v-if="role.includes(0)">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)" style="" class="text-one"
                                    :title="scope.row.customName">{{ scope.row.customName }}</div>
                            </template>
                        </el-table-column> -->
                        <el-table-column align="center" width="300" label="操作" class-name="small-padding fixed-width">
                            <template #default="scope">
                                <div :class="returnClass(scope.row)">
                                    <el-button size="mini" type="primary" @click="goDetail(scope.row)">查看
                                    </el-button>
                                    <el-button size="mini" type="primary" @click="goQueryResult(scope.row)">识别
                                    </el-button>
                                    <el-button size="mini" type="success" @click="goRecordConfirm(scope.row)">{{
                                        scope.row.isFinish == 2 ? '取消' : '确认' }}
                                    </el-button>
                                    <el-button size="mini" type="danger" @click="goRecordDelete(scope.row)">{{
                                        scope.row.isDelete == 1 ? '恢复' : '删除' }}
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-loading="loading" v-if="groupName != ''"
                    style="width: 100%;height: calc(100vh - 300px);overflow-y: auto;">
                    <el-collapse>
                        <el-collapse-item :title="items" :name="indexs" v-for="(items, indexs) in collapseList">
                            <div class="bre9e mt15">
                                <el-table header-cell-class-name="bgf2" show-overflow-tooltip class="sssstt mb15"
                                    height="calc(100vh - 550px)" :data="showObj[items]" highlight-current-row>
                                    <!-- <el-table-column align="center" type="selection" width="55" /> -->
                                    <el-table-column align="center" fixed="left" type="index" label="序号" width="60" />
                                    <el-table-column align="center" width="200" label="归属客户" prop="customAbbreviation" />
                                    <el-table-column align="center" width="200" label="应用场景" prop="sceneName" />
                                    <el-table-column align="center" width="90" label="状态" prop="isFinish">
                                        <template #default="scope">
                                            <div style="color: #c45656;" v-if="scope.row.finishName == '删除'">删除</div>
                                            <div style="color: rgb(40 12 175);" v-if="scope.row.finishName == '填报'">填报</div>
                                            <div style="color: #eea507;" v-if="scope.row.finishName == '识别'">识别</div>
                                            <div style="color: #048d17;" v-if="scope.row.finishName == '确认'">确认</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" width="350" label="表单名称" prop="fileName" />
                                    <el-table-column align="center" width="200" sortable label="采集人员" prop="memberName" />
                                    <el-table-column align="center" width="150" sortable label="采集时间" prop="createTime">
                                        <template #default="scope">
                                            <div>{{ timestampToTime(scope.row.createTime) }}</div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column align="center" fixed="right" width="400" label="操作"
                                        class-name="small-padding fixed-width">
                                        <template #default="scope">
                                            <el-button size="mini" type="primary" @click="goDetail(scope.row)">查看
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </template>


            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" />
        </el-card>
    </div>
</template>

<script>

import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms } from "@/api/adminservice/custom";
import { formQuery, deleteForm, formRelease, formStop } from "@/api/adminservice/form"
import { download } from "@/api/adminservice/public"
import { queryScene, queryAuthMembers, sceneInfo } from "@/api/adminservice/scene"

import { queryAllRecords } from "@/api/adminservice/formRecord"
import { strokeQuery, queryResult, recordCancelConfirm, recordCancelDelete, recordConfirm, recordDelete,recordBatchDelete } from "@/api/adminservice/formRecord";

export default {
    name: "formRecord",
    components: {
    },
    data() {
        return {
            isSBBolean: false,
            loadTxt: '识别进度',
            visible: false,
            isSxShow: true,
            isCanvas: false,
            collapseList: [],
            showObj: {},
            groupName: '',
            active: 2,
            customList: [],
            sortName: '',
            isFinishList: [
                { name: '填报', value: 0 },
                { name: '识别', value: 1 },
                { name: '确认', value: 2 },
                { name: '删除', value: 3 },
            ],
            statusList: [
                { name: '未铺设', value: 0 },
                { name: '铺设中', value: 1 },
                { name: '铺设成功', value: 2 },
                { name: '铺设失败', value: 3 },
                { name: '发布', value: 4 },
                { name: '停用', value: 5 },
            ],
            peopleList: [],
            sceneList: [],
            timestampToTime,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            selectList: [],
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 50,
                userIdList: [],
                // fileName: '',
                sceneId: '',
                formId: '',
                // finishName: '',
                customId: '',
                createTime: [],
                memberName: '',
                beginTime: '',
                endTime: '',
                isDelete: '',
                finishtxtStutasList: []
            },
            formList: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            authMembersList: [],
            oldList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        if (process.env.NODE_ENV == 'development') {
            this.getList();
        }
        // 
        this.getSceneList()
        this.getFormList();
        // this.getQueryAuthMembers();
    },
    watch: {

    },
    methods: {
        identifyAll() {
            console.log(this.selectList);
            if (this.selectList.length == 0) {
                this.$message.error('请选择数据')
                return
            }
            let arr1 = []
            var jdNum = 0
            this.isSBBolean = true;
            let that = this;
            this.loadTxt = '识别进度0/' + this.selectList.length
            this.selectList.forEach(e => {
                arr1.push(new Promise(function (resolve, reject) {
                    queryResult({ recordId: e.recordId }).then(res => {
                        if (res.data.code == 1) {
                            jdNum++;
                            that.loadTxt = '识别进度' + jdNum + '/' + that.selectList.length

                        } else {

                        }
                        resolve(res.data)
                    })
                }))

            })
            Promise.all(arr1).then((result) => {
                this.isSBBolean = false;
                this.getList()
            })
        },
        confirmAll() {
            console.log(this.selectList);
            if (this.selectList.length == 0) {
                this.$message.error('请选择数据')
                return
            }
            var recordIds = []
            this.selectList.forEach(e => {
                recordIds.push(e.recordId)
            })
            recordConfirm({ recordIds: recordIds.toString() }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("确认成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        delAll() {
            console.log(this.selectList);
            if (this.selectList.length == 0) {
                this.$message.error('请选择数据')
                return
            }
            var recordIds = []
            this.selectList.forEach(e => {
                recordIds.push(e.recordId)
            })
            recordBatchDelete({ recordIds: recordIds.toString() }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        returnClass(item) {
            if (item.finishName == '已删除') {
                return 'bg-b1b3b8 tabtxt'
            } else if (item.finishName == '填报') {
                return 'tabtxt'
            } else if (item.finishName == '已识别') {
                return 'bg-c6e2ff tabtxt'
            } else if (item.finishName == '已确认') {
                return 'bg-337ecc tabtxt'
            }
        },
        changeList() {
            // this.getList()
        },
        changeCustom(e) {
            this.peopleList = [];
            this.queryParams.sceneId = '';
            this.queryParams.userIdList = [];
            this.queryParams.formId = '';
            queryScene({ customId:this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId') }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId == this.queryParams.customId
                    })
                }
                this.sceneList = res.data.data;
            });
            formQuery({ customId: this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId') }).then(res => {
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId == this.queryParams.customId
                    })
                }
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.isDesign == 1
                })
                this.formList = res.data.data;
            });
            // this.getList()
        },
        changeScene(e) {
            this.peopleList = [];
            this.queryParams.formId = '';
            this.queryParams.userIdList = [];
            if (this.queryParams.sceneId !== '') {
                sceneInfo({ sceneId: this.queryParams.sceneId }).then(res => {
                    this.peopleList = res.data.data.memberList;
                })
            }
            formQuery({ customId: this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId') }).then(res => {
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId == this.queryParams.customId
                    })
                }
                if (this.queryParams.sceneId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneId == this.queryParams.sceneId
                    })
                }
                this.formList = res.data.data;
                // this.getList()
            });
        },
        setSort() {
            if (this.sortName != '') {
                this.sortName = ''
            }
        },
        setGroup() {
            if (this.groupName != '') {
                this.groupName = ''
            }
        },
        changeGroupName(e) {
            this.isCanvas = false;
            this.sortName = '';
            this.dataList = JSON.parse(JSON.stringify(this.oldList))
            this.groupName = e;
            this.collapseList = [];
            var arrts = [];
            this.showObj = {};
            this.dataList.forEach((e, i) => {
                e.index = i;
                if (e.member) {
                    if (e.member.memberName) {
                        e.memberName = e.member.memberName
                    } else {
                        e.memberName = e.member.memberAccount
                    }
                }
                if (this.groupName == '采集人员') {
                    if ((arrts.indexOf(e.memberName) === -1)) {
                        this.showObj[e.memberName] = []
                        arrts.push(e.memberName)
                    } else {

                    }
                    this.showObj[e.memberName].push(e)

                } else if (this.groupName == '采集日期') {
                    if ((arrts.indexOf(timestampToTime(e.createTime).substring(0, 10)) === -1)) {
                        this.showObj[timestampToTime(e.createTime).substring(0, 10)] = []
                        arrts.push(timestampToTime(e.createTime).substring(0, 10))
                    }
                    this.showObj[timestampToTime(e.createTime).substring(0, 10)].push(e)
                } else if (this.groupName == '状态') {
                    if ((arrts.indexOf(e.finishName) === -1)) {
                        this.showObj[e.finishName] = []
                        arrts.push(e.finishName)
                    }
                    this.showObj[e.finishName].push(e)
                }

            })
            arrts.sort(function (a, b) {
                if (a > b) { return -1; }
                if (a < b) { return 1; }
                return 0;
            })
            this.collapseList = arrts;
            console.log(this.showObj)
            setTimeout(() => {
                this.isCanvas = true;
            }, 1000)
        },
        changeSortName(e) {
            this.groupName = ''
            if (e == this.sortName) {
                this.sortName = ''
                console.log(this.oldList)
                this.dataList = JSON.parse(JSON.stringify(this.oldList))
            } else {
                this.sortName = e
                if (e == '采集人员') {
                    this.dataList.sort(function (a, b) {
                        if (a.memberName < b.memberName) { return -1; }
                        if (a.memberName > b.memberName) { return 1; }
                        return 0;
                    })
                }
                if (e == '采集时间') {
                    this.dataList.sort(function (a, b) {
                        if (a.createTime > b.createTime) { return -1; }
                        if (a.createTime < b.createTime) { return 1; }
                        return 0;
                    })
                }
            }

        },
        getQueryAuthMembers() {
            queryAuthMembers().then(res => {
                this.peopleList = res.data.data;
            })
        },
        getFormList() {
            formQuery({ customId: this.$storage.get('customId') }).then(res => {
                this.formList = res.data.data;
            });
        },
        changeActive(num) {
            this.active = num

            if (num == 2) {

            }
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.fileName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        getSceneList() {
            this.customList = []
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
            });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('010');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息技术有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
            });
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.queryParams.finishStutasList = []
            // console.log(e);
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (this.queryParams.createTime && this.queryParams.createTime.length != 0) {
                this.queryParams.beginTime = this.queryParams.createTime[0]
                this.queryParams.endTime = this.queryParams.createTime[1]
            } else {
                this.queryParams.beginTime = ''
                this.queryParams.endTime = ''
            }
            // this.peopleList = [];
            this.groupName = '';
            this.sortName = '';
            this.isCanvas = false;
            this.loading = true;
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            if (this.queryParams.finishtxtStutasList.includes(3)) {
                this.queryParams.finishtxtStutasList.forEach(e => {
                    if (e != 3) {
                        this.queryParams.finishStutasList.push(e)
                    }
                })
                this.queryParams.isDelete = 1
            } else {
                this.queryParams.finishStutasList = this.queryParams.finishtxtStutasList
                this.queryParams.isDelete = 0
            }
            if (this.queryParams.finishtxtStutasList.length == 0) {
                this.queryParams.isDelete = ''
            }
            console.log(1);
            queryAllRecords(this.queryParams).then(res => {
                console.log(res);
                let arr = []
                res.data.data.list.forEach(e => {
                    if (e.isDelete == 1) {
                        e.finishName = '已删除'
                    } else {
                        if (e.isFinish == 0) {
                            e.finishName = '填报'
                        } else if (e.isFinish == 1) {
                            e.finishName = '已识别'
                        } else if (e.isFinish == 2) {
                            e.finishName = '已确认'
                        }
                    }
                    // if (this.peopleList.indexOf(e.memberName) != -1) {

                    // } else {
                    //     this.peopleList.push(e.memberName)
                    // }
                    arr.push(new Promise(function (resolve, reject) {
                        strokeQuery({ pageNum: 1, recordId: e.recordId }).then(res => {
                            e.strokeList = res.data.data;
                            resolve(res.data.data)
                        })
                    }))
                })
                Promise.all(arr).then((result) => {
                    this.loading = false;
                    setTimeout(() => {
                        this.isCanvas = true;
                    })
                })
                this.dataList = res.data.data.list;
                this.dataList.forEach((e, i) => {
                    e.index = i;
                    if (e.member) {
                        if (e.member.memberName) {
                            e.memberName = e.member.memberName
                        } else {
                            e.memberName = e.member.memberAccount
                        }
                    }
                })
                this.oldList = JSON.parse(JSON.stringify(this.dataList));

                this.total = res.data.data.total;
                // setTimeout(() => {
                //     this.isCanvas = true;
                // }, 5000)
            }).catch(e => {
                this.loading = false;
            });
        },

        // 停用
        handleStop(row) {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            formStop({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        handleRelease() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            formRelease({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleXz() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            download(this.selectObj.formUrl + '/original.pdf', this.selectObj.formName)
        },
        hadlePs() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            let routeUrl = this.$router.resolve({
                path: '/ZBDesigner',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userId: JSON.parse(this.$storage.get('userInfo')).userId, formId: this.selectObj.formId }
            })
            window.open(routeUrl.href, '_blank')

            // var url = "http://192.168.101.116:8080/?userId=" + JSON.parse(this.$storage.get('userInfo')).userId + "&formId=" + this.selectObj.formId
            // window.open(url)
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            deleteForm({ formId: row.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/formRecordIdentify",
                query: {
                    recordData: JSON.stringify(row),
                    dataList: JSON.stringify(this.dataList)
                }
            });
        },
        goQueryResult(row) {
            queryResult({ recordId: row.recordId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("识别成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        goRecordConfirm(row) {
            var recordIds = [row.recordId]
            if (row.isFinish == 2) {
                recordCancelConfirm({ recordId: row.recordId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("取消成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            } else {
                recordConfirm({ recordIds: recordIds.toString() }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("确认成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            }

        },
        goRecordDelete(row) {
            if (row.isDelete == 0) {
                recordDelete({ recordId: row.recordId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("删除成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            } else {
                recordCancelDelete({ recordId: row.recordId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("恢复成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            }

        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/formMainAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/formMainAdd",
                query: {
                    formId: row.formId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.image {
    width: 100%;
    display: block;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #21ac74
}

.wbba {
    word-break: break-all;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

/deep/ .pagination-container {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

/deep/ .el-table .cell {
    padding: 0 !important;
}

/deep/ .el-table--enable-row-transition .el-table__body td.el-table__cell {
    padding: 0 !important;
}

.bg-c6e2ff {
    background: #c6e2ff;
}

.bg-337ecc {
    background: #6192c7;
}

.bg-b1b3b8 {
    background: #b1b3b8;
}

.tabtxt {
    /* padding: 8px 0; */
    line-height: 49px;
    height: 49px;
}
</style>